/* Auto-generated file created by Dan Tovbein 13/02/2019 at 00:21:47hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../utils/_helpers";
import styles from "./styles.css";

const FormItem = (props) => {
  const id = props.label.replace(/ /g, '_').toLowerCase();
  return (
    <div
      className={trimClassNames(["form-item", styles.formItem])}
    >
      <label htmlFor={id}>{props.label} {props.isRequired && <span className={styles.requiredIcon}>*</span>}</label>
      {
        props.type === "textarea" ? (
          <textarea
            id={id}
            onChange={(evt) => props.onChangeHandler(evt.currentTarget.value)}
          />
        ) : (
          <input
            id={id}
            type="text"
            onChange={(evt) => props.onChangeHandler(evt.currentTarget.value)}
          />
        )
      }
    </div>
  )
};

FormItem.defaultProps = {
  type: "text",
  isRequired: false
};

FormItem.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  isRequired: PropTypes.bool,
  onChangeHandler: PropTypes.func.isRequired
};

export default FormItem;
