/* Auto-generated file created by Dan Tovbein 14/03/2019 at 21:55:15hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../utils/_helpers";
import arrowLeft from "../../../media/images/icons/arrow_left.svg";
import arrowRight from "../../../media/images/icons/arrow_right.svg";
import styles from "./styles.css";

const ViewSliderHeaderNav = props => {
  return (
    <nav
      className={trimClassNames([
        "view-slider-header-nav",
        styles.viewSliderHeaderNav
      ])}
    >
      {props.pagination && (
        <div className={styles.arrowLeft} onClick={props.onPrevHandler}>
          <img src={arrowLeft} />
        </div>
      )}
      {props.pagination && (
        <div className={styles.arrowRight} onClick={props.onNextHandler}>
          <img src={arrowRight} />
        </div>
      )}
    </nav>
  );
};

ViewSliderHeaderNav.propTypes = {
  currentPage: PropTypes.number,
  pagination: PropTypes.any,
  onPrevHandler: PropTypes.func.isRequired,
  onNextHandler: PropTypes.func.isRequired
};

export default ViewSliderHeaderNav;
