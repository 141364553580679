import axios from "axios";
import { ENVIRONMENTS, SERVER_ENVIRONMENT_MODE } from "../../env";

export const GET_ALL = "GET_ALL";

/**
 *
 * @param entity
 * @returns {function(*=, *): Promise<any>}
 */
export const getAll = entity => {
  return (dispatch, getState) => {
    const ACTION = `${GET_ALL}_${entity.toUpperCase()}`;
    return new Promise((resolve, reject) => {
      return checkForEntity(entity)
        .then(() => {
          dispatch({ type: `${ACTION}_REQUEST`, payload: null });
          return axios.get(
            `${ENVIRONMENTS[SERVER_ENVIRONMENT_MODE].url}${entity}?active=1`,
            {}
          );
        })
        .then(payload => {
          return resolve(
            dispatch({
              type: `${ACTION}_SUCCESS`,
              payload: { ...payload.data }
            })
          );
        })
        .catch(error => {
          console.log(error);
          return reject(
            dispatch({
              type: `${ACTION}_FAILED`,
              payload: { ...error }
            })
          );
        });
    });
  };
};

export const sendEmail = (body = {}) => {
  return (dispatch, getState) => {
    const ACTION = "SEND_EMAIL";
    return new Promise((resolve, reject) => {
      return axios
        .post(
          `${ENVIRONMENTS[SERVER_ENVIRONMENT_MODE].url}/general/email`,
          body
        )
        .then(payload => {
          return resolve(
            dispatch({
              type: `${ACTION}_SUCCESS`,
              payload: null
            })
          );
        })
        .catch(error => {
          return reject(
            dispatch({
              type: `${ACTION}_FAILED`,
              payload: { ...error }
            })
          );
        });
    });
  };
};

export const checkForEntity = (entity = "") => {
  return new Promise((resolve, reject) => {
    if (entity === "") {
      return reject({
        error: "Undefined entity"
      });
    } else {
      return resolve({
        entity
      });
    }
  });
};
