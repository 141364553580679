/* Auto-generated file created by dtovbeinJC 07/11/2018 at 07:43:28hs */

import { APP } from "../../constants/";
import {SET_WINDOWS_SIZE_TYPE, SHOW_OR_HIDE_PORTAL} from "./actions";

export const initialState = {
  name: "",
  windowsSizeType: "",
  showPortal: true
}

export default function reducer(state=initialState, action) {
  switch(action.type) {
    case APP: {
      return {
        ...state,
        name: action.payload.name
      };
    }

    case SET_WINDOWS_SIZE_TYPE: {
      return {
        ...state,
        windowsSizeType: action.payload.type
      }
    }

    case SHOW_OR_HIDE_PORTAL: {
      return {
        ...state,
        showPortal: action.payload.show
      }
    }

    default:
      return state;
  }
}