/* Auto-generated file created by dtovbeinJC 04/11/2018 at 21:31:53hs */

import React, {Fragment} from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../utils/_helpers";
import styles from "./styles.css";
import ViewSliderItemDetailsWrapper from "../ViewSliderItemDetails/ViewSliderItemDetailsWrapper";
import ViewSliderItemWrapper from "./ViewSliderItemWrapper";

const ViewSliderItem = (props) => {
  return (
    <div
      className={trimClassNames(["view-slider-item", styles.viewSliderItem])}
      style={ props.customStyle }
    >
      <ViewSliderItemWrapper>
        { props.children }
      </ViewSliderItemWrapper>
    </div>
  );
};

ViewSliderItem.propTypes = {
  children: PropTypes.node
}

export default ViewSliderItem;