/* Auto-generated file created by Dan Tovbein 16/03/2019 at 19:18:46hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../../../../utils/_helpers";
import styles from "./styles.css";

const ViewSliderItemWrapperInfoTitle = (props) => {
  return (
    <h1
      className={trimClassNames(["view-slider-item-wrapper-info-title", styles.viewSliderItemWrapperInfoTitle])}
    >
      { props.title }
    </h1>
  )
};

ViewSliderItemWrapperInfoTitle.propTypes = {
  title: PropTypes.string
};

export default ViewSliderItemWrapperInfoTitle;
