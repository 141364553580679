/* Auto-generated file created by dtovbeinJC 08/11/2018 at 18:16:03hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../utils/_helpers";
import styles from "./styles.css";

const props = {
  legend: PropTypes.string
};

const TitleView = ({...props}) => (
  <div className={trimClassNames(["title-view",
    styles.titleView])}>
    <h1 className={styles.title}>{ props.legend }</h1>
    <div className={styles.miscellany} />
  </div>
);

export default TitleView;