/* Auto-generated file created by dtovbeinJC 05/11/2018 at 16:51:54hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { trimClassNames } from "../../../utils/_helpers";
import { parseFullDate } from "../../../utils/utils";
import * as CSSVariables from "../../../sass/_variables";
import styles from "./styles.css";
import ButtonRounded from "../../__shared/Button/ButtonRounded";
import ViewSliderItemWrapperImage from "../../ViewSlider/ViewSliderItem/ViewSliderItemWrapper/ViewSliderItemWrapperImage";
import ViewSliderItemWrapperInfo from "../../ViewSlider/ViewSliderItem/ViewSliderItemWrapper/ViewSliderItemWrapperInfo";
import ViewSliderItemWrapperInfoDate from "../../ViewSlider/ViewSliderItem/ViewSliderItemWrapper/ViewSliderItemWrapperInfo/ViewSliderItemWrapperInfoDate";
import ViewSliderItemWrapperInfoTitle from "../../ViewSlider/ViewSliderItem/ViewSliderItemWrapper/ViewSliderItemWrapperInfo/ViewSliderItemWrapperInfoTitle";
import ViewSliderItemWrapperInfoDescription from "../../ViewSlider/ViewSliderItem/ViewSliderItemWrapper/ViewSliderItemWrapperInfo/ViewSliderItemWrapperInfoDescription";
import Image from "../../__shared/Image";
import ClampLines from "react-clamp-lines";

const CourseItem = (props) => {
  const { data, onClickHandler } = props;

  return (
    <div className={trimClassNames(["course-item", styles.courseItem])}>
      <ViewSliderItemWrapperImage>
        <Image src={data.picture} />
      </ViewSliderItemWrapperImage>
      <ViewSliderItemWrapperInfo>
        <ViewSliderItemWrapperInfoDate
          date={`INICIO ${parseFullDate(data.startDate).toUpperCase()}`}
        />
        <ViewSliderItemWrapperInfoTitle title={data.title} />
        <ClampLines
          text={data.volanta}
          id="really-unique-id"
          lines={3}
          ellipsis="..."
          className="custom-class"
          innerElement="p"
          buttons={false}
        />
        <footer className={styles.footer}>
          <ButtonRounded
            id={`hckBtnCurso${data.id}`}
            legend="VER MÁS"
            backgroundColor={CSSVariables.ORANGE_COLOR_2}
            onClickHandler={() => onClickHandler(data)}
          />
        </footer>
      </ViewSliderItemWrapperInfo>
    </div>
  );
};

CourseItem.propTypes = {
  data: PropTypes.shape({
    author: PropTypes.string,
    description: PropTypes.string,
    picture: PropTypes.string,
    startDate: PropTypes.number,
    title: PropTypes.string,
    volanta: PropTypes.string,
  }).isRequired,
  onClickHandler: PropTypes.func.isRequired,
};

export default withRouter(CourseItem);
