/* Auto-generated file created by Dan Tovbein 15/02/2019 at 07:14:11hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../../utils/_helpers";
import styles from "./styles.css";

const ViewSliderItemDetailsWrapperSide = (props) => {
  return (
    <section className={trimClassNames(["view-slider-item-details-wrapper-side", styles.viewSliderItemDetailsWrapperSide])}>
      { props.children }
    </section>
  )
};

export default ViewSliderItemDetailsWrapperSide;
