/* Auto-generated file created by dtovbeinJC 21/11/2018 at 18:20:42hs */

import * as React from "react";
import { NavLink } from "react-router-dom";
import * as PropTypes from "prop-types";
import {} from "./helpers";
import { trimClassNames } from "../../../utils/_helpers";
import styles from "./styles.css";

const MainHeaderNavSubButton = ({ legend, to, onClickHandler }) => (
  <div
    className={trimClassNames([
      "main-header-nav-sub-button",
      styles.mainHeaderNavSubButton,
    ])}
  >
    <a href={to}>
      <span>{legend}</span>
    </a>
  </div>
);

MainHeaderNavSubButton.propTypes = {
  legend: PropTypes.string,
  to: PropTypes.string,
  onClickHandler: PropTypes.func,
};

export default MainHeaderNavSubButton;
