/* Auto-generated file created by dtovbeinJC 06/11/2018 at 21:36:43hs */

import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as PropTypes from "prop-types";
import { setNameToComponent } from "./actions";
import {getAll} from "../../actions/api";
import ViewSlider from "../../components/ViewSlider";
import VideoItem from "../../components/Videos/VideoItem";
import View from "../../components/View";
import ViewContent from "../../components/View/ViewContent";
import backgroundImage from "../../media/images/views/backgrounds/NEW-FONDOS_8.svg";

class Videos extends React.Component {
  static propTypes = {
    videosReducer: PropTypes.shape({
      videos: PropTypes.object,
      layout: PropTypes.shape({
        columns: PropTypes.number,
        rows: PropTypes.number
      }),
      name: PropTypes.string
    }),
    getAll: PropTypes.func,
    setNameToComponent: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      __name: "Videos"
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { getAll, setNameToComponent } = this.props;
    const { __name } = this.state;

    const promises = [
      setNameToComponent(__name),
      getAll('video')
    ];

    Promise.all(promises)
      .then((payload) => {})
      .catch((error) => console.log(error));
  }

  render() {
    const {videosReducer: {videos, layout: {columns, rows}}, marginTop} = this.props;
    return (
      <View
        marginTop={marginTop}
        backgroundImage={backgroundImage}
      >
        <ViewContent>
          <ViewSlider
            data={videos}
            detailsComponent={null}
            itemComponent={VideoItem}
            columns={columns}
            rows={rows}
            title={"Videos"}
            marginTop={marginTop}
          />
        </ViewContent>
      </View>
    );
  }
}

export default connect(
  store => ({
    videosReducer: store.videosReducer
  }),
  dispatch => bindActionCreators({
    setNameToComponent,
    getAll
  }, dispatch),
  null,
  { withRef: true })(Videos);