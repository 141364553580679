// Auto-generated file created by Dan Tovbein 12/02/2019 at 21:46:59hs
// Auto-generated file created by Dan Tovbein 12/02/2019 at 21:40:37hs
// Auto-generated file created by Dan Tovbein 12/02/2019 at 19:14:21hs
// Auto-generated file created by Dan Tovbein 12/02/2019 at 08:51:58hs
import {combineReducers} from "redux";
/* reducers */
/* containers */
import MembersReducer from "../containers/Members/reducer";
import ContactReducer from "../containers/Contact/reducer";
import AppReducer from "../containers/App/reducer";
import EditorialsReducer from "../containers/Editorials/reducer";
import VideosReducer from "../containers/Videos/reducer";
import ArticlesReducer from "../containers/Articles/reducer";
import CoursesReducer from "../containers/Courses/reducer";
export default combineReducers({
/* key:value */
membersReducer: MembersReducer,
contactReducer: ContactReducer,
appReducer: AppReducer,
editorialsReducer: EditorialsReducer,
videosReducer: VideosReducer,
articlesReducer: ArticlesReducer,
coursesReducer: CoursesReducer,
});
