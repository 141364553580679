/* Auto-generated file created by dtovbeinJC 05/11/2018 at 23:26:09hs */
import {
  ARTICLES
} from "../../constants/index";

export const GET_ARTICLES = "GET_ARTICLES";

export const setNameToComponent = (componentName) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { articlesReducer } = getState();
    return resolve(dispatch({
      type: ARTICLES,
      payload: {
        name: componentName
      }
    }));
  })
  .then((payload) => Promise.resolve({...payload}))
  .catch((error) => Promise.reject({...error}));
};