/* Auto-generated file created by dtovbeinJC 06/11/2018 at 21:36:43hs */

import { VIDEOS } from "../../constants/";
import {GET_VIDEOS} from "./actions";
import {EXTRA_SMALL, MEDIUM, SMALL} from "../../constants/shared";
import {SET_WINDOWS_SIZE_TYPE} from "../App/actions";
import {GET_ALL} from "../../actions/api";
import {GET_COURSES} from "../Courses/actions";

export const initialState = {
  name: "",
  videos: null,
  layout: {
    columns: 1,
    rows: 1
  }
}

export default function reducer(state=initialState, action) {
  switch(action.type) {
    case VIDEOS: {
      return {
        ...state,
        name: action.payload.name
      };
    }

    case `${GET_ALL}_VIDEO_REQUEST`: {
      return {
        ...state
      }
    }

    case `${GET_ALL}_VIDEO_SUCCESS`: {
      return {
        ...state,
        videos: action.payload
      }
    }

    case `${GET_COURSES}_VIDEO_FAILED`: {
      return {
        ...state
      }
    }

    case SET_WINDOWS_SIZE_TYPE: {
      const { type } = action.payload;
      return {
        ...state,
        layout: {
          columns: (type === EXTRA_SMALL || type === SMALL) ? 1 : (type === MEDIUM) ? 2 : 4,
          rows: 2
        }
      };
    }

    default:
      return state;
  }
}