/* Auto-generated file created by Dan Tovbein 21/12/2018 at 12:16:09hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import {NavLink, Link} from "react-router-dom";
import { trimClassNames } from "../../../utils/_helpers";
import styles from "./styles.css";

const Button = (props) => {
  return (
    props.to ? (
      <a
        className={trimClassNames(["button", styles.button])}
        href={props.to}
        target="_blank"
      >
        { props.children }
      </a>
    ) : (
      <div
        className={trimClassNames(["button", styles.button])}
        role="button"
        onClick={props.onClickHandler}
      >
        { props.children }
      </div>    
    )
  )
};

Button.propTypes = {
  onClickHandler: PropTypes.func,
  to: PropTypes.string
};

export default Button;
