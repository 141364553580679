/* Auto-generated file created by Dan Tovbein 13/02/2019 at 19:11:15hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import {} from "./helpers";
import { trimClassNames } from "../../../utils/_helpers";
import styles from "./styles.css";

class Image extends React.Component {

  static propTypes = {
    src: PropTypes.string,
    fromPath: PropTypes.bool
  };

  static getDerivedStateFromProps(nextProps, nextState) {
    if(nextProps.fromPath && nextProps.src !== '' && nextProps.src !== nextState.src) {
      return {
        image: require("../../../media/images/" + nextProps.src)
      };
    } else {
      return {
        image: nextProps.src
      }
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      __name: "Image",
      src: '',
      image: null
    };
  }

  render() {
    const {image} = this.state;
    return (
      <React.Fragment>
        {
          (image) && (
            <div
              className={trimClassNames(["image", styles.image])}
              style={{backgroundImage: `url(${image})`}}
            />
          )
        }
      </React.Fragment>
    );
  }
}

export default Image;

