/* Auto-generated file created by dtovbeinJC 05/11/2018 at 17:40:20hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../utils/_helpers";
import {
  parseDate,
  parseFullDate,
  getNavigatorClass,
} from "../../../utils/utils";
import styles from "./styles.css";
import ViewSliderItemDetails from "../../ViewSlider/ViewSliderItemDetails";
import ViewSliderItemDetailsHeader from "../../../components/ViewSlider/ViewSliderItemDetails/ViewSliderItemDetailsHeader";
import ViewSliderItemDetailsWrapper from "../../ViewSlider/ViewSliderItemDetails/ViewSliderItemDetailsWrapper";
import ViewSliderItemDetailsWrapperSide from "../../ViewSlider/ViewSliderItemDetails/ViewSliderItemDetailsWrapperSide";
import TabSection from "../../ViewSlider/ViewSliderItemDetails/TabSection";
import ButtonRounded from "../../__shared/Button/ButtonRounded";
import Image from "../../__shared/Image";
import HtmlText from "../../__shared/HtmlText";
import fbIcon from "../../../media/images/icons/PS-01.svg";
import twIcon from "../../../media/images/icons/PS-04.svg";
import mlIcon from "../../../media/images/icons/PS-02.svg";
import cpIcon from "../../../media/images/icons/PS-03.svg";

class CourseDetails extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      author: PropTypes.string,
      description: PropTypes.string,
      endDateInscription: PropTypes.number,
      id: PropTypes.number,
      isActive: PropTypes.bool,
      orderIndex: PropTypes.number,
      picture: PropTypes.string,
      startDate: PropTypes.number,
      startDateInscription: PropTypes.number,
      subtitle: PropTypes.string,
      tabs: PropTypes.any,
      title: PropTypes.string,
      volanta: PropTypes.string,
    }).isRequired,
    onCloseHandler: PropTypes.func.isRequired,
  };

  static getDerivedStateFromProps(nextProps, nextState) {
    if (nextProps.data.tabs !== null) {
      const tabs = JSON.parse(window.atob(nextProps.data.tabs));
      return {
        tabs,
        currentTab:
          nextState.currentTab === null ? tabs[0] : nextState.currentTab,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      __name: "CourseDetails",
      tabs: null,
      currentTab: null,
    };
  }

  render() {
    const { data, onCloseHandler } = this.props;
    const { tabs } = this.state;
    const today = new Date();
    let thisUrl = `https://puntoseguido.com/cursos.html#/curso?${data.id}`;
    let fbLink = String(
      `http://facebook.com/sharer/sharer.php?u=${thisUrl}`
    ).replace("#", "%23");

    let twLink = String(
      `https://twitter.com/intent/tweet?text=Punto Seguido Curso &url=${thisUrl}`
    ).replace("#", "%23");

    let mlLink = String(
      `mailto:?subject=Punto Seguido&amp;body=Entrá en este link ${thisUrl}`
    );

    let dummy = document.createElement("div");
    let tmpTxt = document.createElement("input");
    tmpTxt.style.display = "none";
    tmpTxt.readOnly = true;
    tmpTxt.value = thisUrl;
    const copy2ClipBoard = (e) => {
      tmpTxt.style.display = "inline";
      tmpTxt.select();
      document.execCommand("copy");
      tmpTxt.style.display = "none";
      console.log(e.target);
      e.target.style.opacity = "0.3";
    };

    dummy.appendChild(tmpTxt);
    document.body.appendChild(dummy);

    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return (
      <ViewSliderItemDetails>
        <div
          className={trimClassNames([
            "course-details",
            styles.courseDetails,
            getNavigatorClass(),
          ])}
        >
          <div>
            <a target="_blank" href={fbLink}>
              <img width="31" src={fbIcon} />
            </a>
            <a target="_blank" href={twLink}>
              <img width="31" src={twIcon} />
            </a>
            <a target="_blank" href={mlLink}>
              <img width="31" src={mlIcon} />
            </a>
            <a onClick={copy2ClipBoard}>
              <img width="31" src={cpIcon} />
            </a>
          </div>

          <div className={styles.wrapperImageMobile}>
            <Image src={data.picture} />
          </div>
          <nav className={styles.extraNavMobile}>
            {data.formLink &&
            today.getTime() > data.startDateInscription &&
            tomorrow.getTime() < data.endDateInscription ? (
              <a href={data.formLink} target="_blank">
                <ButtonRounded legend={"INSCRIBITE"} />
              </a>
            ) : (
              "Inscripción no disponible"
            )}
          </nav>
          <ViewSliderItemDetailsHeader
            title={data.title}
            subtitle={data.subtitle}
            tabs={{
              data: tabs,
              onClickHandler: this.onClickHandlerTab,
            }}
            onCloseHandler={onCloseHandler}
          />
          <ViewSliderItemDetailsWrapper>
            <ViewSliderItemDetailsWrapperSide>
              <div style={styles.headerContent}>
                <p>
                  <span className="highlight">{data.volanta}</span>
                  <span>
                    Docentes: <span className="highlight">{data.author}</span>
                  </span>
                </p>
                <HtmlText htmlText={data.description} />
              </div>
              {tabs && <TabSection data={this.state.currentTab} />}
            </ViewSliderItemDetailsWrapperSide>

            <ViewSliderItemDetailsWrapperSide>
              <div className={styles.wrapperImage}>
                <Image src={data.picture} />
              </div>
              <div className={styles.extraInfo}>
                <span className={styles.span}>
                  <span styles={styles.arrowRight}>▶</span> Inscripciones:{" "}
                  {parseDate(data.startDateInscription)} al{" "}
                  {parseDate(data.endDateInscription)}
                </span>
                <span className={styles.span}>
                  <span styles={styles.arrowRight}>▶</span> ︎Inicio:{" "}
                  {parseFullDate(data.startDate)}
                </span>
              </div>
              <nav className={styles.extraNav}>
                {data.formLink &&
                today.getTime() > data.startDateInscription &&
                tomorrow.getTime() < data.endDateInscription ? (
                  <a href={data.formLink} target="_blank">
                    <ButtonRounded legend={"INSCRIBITE"} />
                  </a>
                ) : (
                  "Inscripción no disponible"
                )}
              </nav>
            </ViewSliderItemDetailsWrapperSide>
          </ViewSliderItemDetailsWrapper>
        </div>
      </ViewSliderItemDetails>
    );
  }

  onClickHandlerTab = (tab) => {
    const { tabs } = this.state;
    const data = Object.values(tabs).filter((t) => t.label === tab)[0];
    this.setState({
      currentTab: data,
    });
  };

  getMoreInfo = () => {};

  suscribe = () => {};
}

export default CourseDetails;
