/* Auto-generated file created by dtovbeinJC 21/11/2018 at 15:20:07hs */

import * as React from "react";
import { NavLink } from "react-router-dom";
import * as PropTypes from "prop-types";
import {} from "./helpers";
import { trimClassNames } from "../../../utils/_helpers";
import styles from "./styles.css";
import MainHeaderNavSubButton from "../MainHeaderNavSubButton";
const MainHeaderNavButton = (props) => {
  return (
    <div
      className={trimClassNames([
        "main-header-nav-button",
        styles.mainHeaderNavButton,
      ])}
    >
      <span className={styles.legend}>
        <a href={props.to || props.basePath}>{props.legend}</a>
      </span>

      {props.options && (
        <div className={styles.submenu}>
          {props.options.map((option) => (
            <MainHeaderNavSubButton
              key={option.legend}
              legend={option.legend}
              to={option.to}
              onClickHandler={props.onClickHandler}
            />
          ))}
        </div>
      )}
    </div>
  );
};

MainHeaderNavButton.propTypes = {
  legend: PropTypes.string.isRequired,
  to: PropTypes.string,
  options: PropTypes.array,
  onClickHandler: PropTypes.func,
};

export default MainHeaderNavButton;
