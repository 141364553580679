/* Auto-generated file created by dtovbeinJC 06/11/2018 at 21:37:33hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import {} from "./helpers";
import { trimClassNames } from "../../../utils/_helpers";
import styles from "./styles.css";
import Image from "../../__shared/Image";
import ViewSliderItemWrapperImage from "../../ViewSlider/ViewSliderItem/ViewSliderItemWrapper/ViewSliderItemWrapperImage";
import ViewSliderItemWrapperInfo from "../../ViewSlider/ViewSliderItem/ViewSliderItemWrapper/ViewSliderItemWrapperInfo";
import { ellipsis } from "../../../utils/utils";
import ViewSliderItemWrapperInfoTitle from "../../ViewSlider/ViewSliderItem/ViewSliderItemWrapper/ViewSliderItemWrapperInfo/ViewSliderItemWrapperInfoTitle";

const EditorialItem = (props) => {
  const { data, onClickHandler } = props;
  return (
    <div className={trimClassNames(["editorial-item", styles.editorialItem])}>
      <ViewSliderItemWrapperImage>
        <Image src={data.picture} />
      </ViewSliderItemWrapperImage>
      <ViewSliderItemWrapperInfo>
        <ViewSliderItemWrapperInfoTitle title={data.title} />
        <span className={styles.topic}>{data.topic}</span>
        <span className={styles.author}>
          Autores: {ellipsis(data.author, 40)}
        </span>
        <span className={styles.company}>Editorial: {data.company}</span>
        <footer className={styles.footer}>
          <div
            id={`hckBtnBook${data.id}`}
            className={styles.btnViewMore}
            onClick={() => onClickHandler(data)}
          >
            VER MÁS >
          </div>
        </footer>
      </ViewSliderItemWrapperInfo>
    </div>
  );
};

EditorialItem.defaultProps = {};

EditorialItem.propTypes = {
  data: PropTypes.shape({
    author: PropTypes.string,
    topic: PropTypes.string,
    company: PropTypes.string,
    picture: PropTypes.string,
    createdAt: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  onClickHandler: PropTypes.func.isRequired,
};

export default EditorialItem;
