/* Auto-generated file created by dtovbeinJC 05/11/2018 at 17:41:32hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import {} from "./helpers";
import { trimClassNames } from "../../../utils/_helpers";
import * as CSSVariables from "../../../sass/_variables";
import styles from "./styles.css";

const props = {
};

const ViewSliderItemDetails = ({...props}) => (
  <div className={trimClassNames(["view-slider-item-details",
                                  styles.viewSliderItemDetails])}>
    { props.children }
  </div>
);

export default ViewSliderItemDetails;