/* Auto-generated file created by Dan Tovbein 15/02/2019 at 18:58:27hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../../utils/_helpers";
import styles from "./styles.css";
import NavLink from "react-router-dom/es/NavLink";

const WrapperLink = (props) => {
  return (
    <div className={trimClassNames(["wrapper-link", styles.wrapperLink])}>
      <a href={props.link}>
        <span className={styles.title}>{props.title}</span>
      </a>
    </div>
  );
};

WrapperLink.defaultProps = {};

WrapperLink.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
};

export default WrapperLink;
