/* Auto-generated file created by Dan Tovbein 17/02/2019 at 11:38:04hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../utils/_helpers";
import styles from "./styles.css";
import NavLink from "react-router-dom/es/NavLink";

class BurgerNav extends React.Component {
  static propTypes = {
    show: PropTypes.bool,
    onClickHandler: PropTypes.func,
  };

  static getDerivedStateFromProps(nextProps, nextState) {
    return {
      show: nextProps.show,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      __name: "BurgerNav",
      show: false,
    };
    this.tabs = [
      { name: "HOME", to: "index.html" },
      { name: "QUIÉNES SOMOS", to: "quienes.html" },
      { name: "CURSOS Y ACTIVIDADES", to: "cursos.html" },
      { name: "PUBLICACIONES", to: "publicaciones.html" },
      { name: "VIDEOS", to: "videos.html" },
      { name: "CONTACTO", to: "contacto.html" },
    ];
  }

  render() {
    const { show } = this.state;
    return (
      <nav
        className={trimClassNames([
          "burger-nav",
          styles.burgerNav,
          `${show ? styles.show : styles.hide}`,
        ])}
      >
        {this.tabs.map((tab) => (
          <a key={tab.name} className={styles.navButton} href={tab.to}>
            {tab.name}
          </a>
        ))}
      </nav>
    );
  }
}

export default BurgerNav;
