/* Auto-generated file created by dtovbeinJC 06/11/2018 at 21:39:17hs */

import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as PropTypes from "prop-types";
import { setNameToComponent } from "./actions";
import { getAll } from "../../actions/api";
import { showOrHidePortal } from "../App/actions";
import View from "../../components/View";
import ViewSlider from "../../components/ViewSlider";
import EditorialItem from "../../components/Editorials/EditorialItem";
import EditorialDetails from "../../components/Editorials/EditorialDetails";
import ViewContent from "../../components/View/ViewContent";
import backgroundImage from "../../media/images/views/backgrounds/NEW-FONDOS_5.svg";

class Editorials extends React.Component {
  static defaultProps = {};

  static propTypes = {
    editorialsReducer: PropTypes.shape({
      editorials: PropTypes.object,
      layout: PropTypes.shape({
        columns: PropTypes.number,
        rows: PropTypes.number,
      }),
      name: PropTypes.string,
    }),
    getAll: PropTypes.func,
    setNameToComponent: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      __name: "Editorials",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { setNameToComponent, getAll } = this.props;
    const { __name } = this.state;

    const promises = [setNameToComponent(__name), getAll("editorial")];

    let ps;
    let idd;
    let sec;
    Promise.all(promises)
      .then((payload) => {
        if (document.URL.split("?").length > 1) {
          ps = document.URL.split("?").length;
          idd = document.URL.split("?")[ps - 1];
          sec = document.URL.split("/")[document.URL.split("/").length - 1];
          if (sec.split("?")[0] === "book")
            document.getElementById(`hckBtnBook${idd}`).click();
        }
        //document.getElementById("hckBtnCurso32").click();
      })
      .catch((error) => console.log(error));
  }

  showOrHidePortal() {
    this.props.showOrHidePortal(true);
  }

  render() {
    const {
      editorialsReducer: {
        editorials,
        layout: { columns, rows },
      },
      marginTop,
    } = this.props;
    if (document.URL.split("?").length > 1) {
      //console.log("locc " + document.URL.split("?")[0].split("#/")[1]);
      if (
        document.URL.split("?")[0].split("#/")[1] === "book" ||
        document.URL.split("?")[1].split("#/")[1] === "book"
      ) {
        //console.log("carga loc store");
        //localStorage.clear();
        //localStorage.setItem("editorials", JSON.stringify(editorials));
      }
    }
    //
    return (
      <View marginTop={marginTop} backgroundImage={backgroundImage}>
        <ViewContent>
          <ViewSlider
            data={editorials}
            detailsComponent={EditorialDetails}
            itemComponent={EditorialItem}
            columns={columns}
            rows={rows}
            title={"Libros y Revistas"}
            marginTop={marginTop}
            showOrHidePortal={() => this.showOrHidePortal()}
          />
        </ViewContent>
      </View>
    );
  }
}

export default connect(
  (store) => ({
    editorialsReducer: store.editorialsReducer,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setNameToComponent,
        getAll,
        showOrHidePortal,
      },
      dispatch
    ),
  null,
  { withRef: true }
)(Editorials);
