/* Auto-generated file created by Dan Tovbein 20/12/2018 at 21:51:44hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../../utils/_helpers";
import styles from "./styles.css";

const ViewSliderItemDetailsWrapper = (props) => {
  return (
    <div
      className={trimClassNames(["view-slider-item-details-wrapper", styles.viewSliderItemDetailsWrapper])}
    >
      { props.children }
    </div>
  )
};

ViewSliderItemDetailsWrapper.propTypes = {};

export default ViewSliderItemDetailsWrapper;
