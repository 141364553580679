/* Auto-generated file created by Dan Tovbein 12/02/2019 at 19:14:20hs */

import axios from "axios";
import { CONTACT } from "../../constants/index";
import { ENVIRONMENTS, SERVER_ENVIRONMENT_MODE } from "../../env";

export const SAMPLE_REQUEST_ACTION = "SAMPLE_REQUEST_ACTION"; // TODO remove or rename

export const setNameToComponent = componentName => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { contactReducer } = getState();
    return resolve(
      dispatch({
        type: CONTACT,
        payload: {
          name: componentName
        }
      })
    );
  })
    .then(payload => Promise.resolve({ ...payload }))
    .catch(error => Promise.reject({ ...error }));
};
