/* Auto-generated file created by dtovbeinJC 02/11/2018 at 20:22:59hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../utils/_helpers";
import {getNavigatorClass} from "../../utils/utils";
import styles from "./styles.css";

const View = (props) => {
  return (
    <section
      className={trimClassNames(["view", styles.view, getNavigatorClass()])}
      style={{
        height: (props.height !== undefined && props.height !== null) ? props.height : `calc(100vh - ${props.marginTop})`,
        backgroundImage: (props.backgroundImage) ? `url(${props.backgroundImage})` : null
      }}
    >
      {props.children}
    </section>
  )
};

View.propsTypes = {
  id: PropTypes.id,
  height: PropTypes.string,
  backgroundImage: PropTypes.any,
  marginTop: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default View;