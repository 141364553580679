/* Auto-generated file created by Dan Tovbein 16/03/2019 at 19:21:03hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import {} from "./helpers";
import { trimClassNames } from "../../../../../utils/_helpers";
import * as CSSVariables from "../../../../../sass/_variables";
import styles from "./styles.css";

const ViewSliderItemWrapperImage = (props) => {
  return (
    <div
      className={trimClassNames(["view-slider-item-wrapper-image", styles.viewSliderItemWrapperImage])}
    >
      { props.children }
    </div>
  )
};

ViewSliderItemWrapperImage.propTypes = {
  children: PropTypes.node
};

export default ViewSliderItemWrapperImage;
