/* Auto-generated file created by Dan Tovbein 23/03/2019 at 20:15:05hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import { trimClassNames } from "../../utils/_helpers";
import styles from "./styles.css";
import arrow from "../../media/images/icons/arrow_scroll_top.svg";

const ArrowScrollTop = (props) => {
  const showArrowTop = () => {
    return (window.scrollY > window.innerHeight);
  }
  return (
    <div 
      className={trimClassNames(["arrow-scroll-top", styles.arrowScrollTop, `${showArrowTop() ? styles.show : styles.hide}`])}
      onClick={() => props.onScrollTopHandler(0)}
    >
      <NavLink
        to="/"
      >
        <img
          className={styles.arrow}
          src={arrow}
        />
      </NavLink>
    </div>
  )
};

ArrowScrollTop.propTypes = {
  onScrollTopHandler: PropTypes.func
};

export default ArrowScrollTop;
