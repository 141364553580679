/* Auto-generated file created by Dan Tovbein 13/02/2019 at 00:22:04hs */

import * as React from "react";
import { trimClassNames } from "../../utils/_helpers";
import styles from "./styles.css";

const Form = (props) => {
  return (
    <form className={trimClassNames(["form", styles.form])}>
      { props.children }
    </form>
  )
};

Form.defaultProps = {};

Form.propTypes = {};

export default Form;
