/* Auto-generated file created by dtovbeinJC 07/11/2018 at 07:43:27hs */

import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import { bindActionCreators } from "redux";
import * as PropTypes from "prop-types";
import { debounce } from "lodash";
import $ from "jquery";
import { trimClassNames } from "../../utils/_helpers";
import { getNavigatorClass } from "../../utils/utils";
import {
  setNameToComponent,
  setWindowsSizeType,
  showOrHidePortal,
} from "./actions";
import * as CSSVariables from "../../sass/_variables.js";
import styles from "./styles.css";
import MainHeader from "../../components/MainHeader";
import Home from "../../components/Home";
import MainFooter from "../../components/MainFooter";
import Editorials from "../Editorials";
import Courses from "../Courses";
import Articles from "../Articles";
import Videos from "../Videos";
import Contact from "../Contact";
import Members from "../Members";
import { EXTRA_SMALL, LARGE, MEDIUM, SMALL } from "../../constants/shared";
import WhoWeAre from "../../components/WhoWeAre";
import BurgerNav from "../../components/BurgerNav";
import ArrowScrollTop from "../../components/ArrowScrollTop";
import Portal2 from "../../components/Portal2";
import Portal3 from "../../components/Portal3";
import Portal4 from "../../components/Portal4";

smoothscroll.polyfill();

class AppHome extends React.Component {
  static propTypes = {
    appReducer: PropTypes.shape({
      name: PropTypes.string,
    }),
    setNameToComponent: PropTypes.func,
    setWindowsSizeType: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.debounceTime = 100;

    this.home = React.createRef();
    //this.portal2 = React.createRef();
    //this.portal3 = React.createRef();
    //this.portal4 = React.createRef();

    this.state = {
      __name: "AppHome",
      platform: getNavigatorClass(),
      showMobileMenu: false,
      showArrowTop: false,
      clickedOnTab: false,
      showPortal: false,
    };
  }

  componentDidMount() {
    this.setState({
      showArrowTop: this.showArrowTop(),
    });
    this.updateWindowSize(window.innerWidth);
    this.viewRefs = [
      { name: "home", matchToPath: "/home", ref: this.home },
      /*
      {
        name: "portal2",
        matchToPath: "/articulo:id",
        ref: this.portal2,
      },
      {
        name: "portal3",
        matchToPath: "/curso:id",
        ref: this.portal3,
      },
      {
        name: "portal4",
        matchToPath: "/publicacion:id",
        ref: this.portal4,
      },*/
    ];
    this.scrollInterval = null;

    /*const view = this.viewRefs.filter(
      (v) => this.props.location.pathname === v.matchToPath
    )[0];
    if (view) {
      view.ref.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }*/

    window.addEventListener("scroll", this.scrolledWindowHandler);
    window.addEventListener("resize", this.resizeHandler);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const view = this.viewRefs.filter(
        (v) => this.props.location.pathname === v.matchToPath
      )[0];
      if (view) {
        view.ref.current.scrollIntoView({ block: "end", behavior: "smooth" });
        this.setState({
          showMobileMenu: false,
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrolledWindowHandler);
    window.removeEventListener("resize", this.resizeHandler);
  }

  scrolledWindowHandler = debounce(() => {
    if (!this.state.clickedOnTab) this.onScrolledWindowHandler();
  }, this.debounceTime);

  resizeHandler = debounce((evt) => {
    this.onResizeHandler(evt);
  }, this.debounceTime);

  onResizeHandler = (evt) => {
    if (evt.currentTarget !== null) {
      const { innerWidth } = evt.currentTarget;
      this.setState({ platform: getNavigatorClass() });
      this.updateWindowSize(innerWidth);
    }
  };

  showArrowTop = () => window.scrollY > window.innerHeight;

  onScrolledWindowHandler = () => {
    const marginTop = this.convertRemToPixels(
      this.state.headerHeight || CSSVariables.HEADER_LARGE_HEIGHT
    );
    const view = this.viewRefs.filter((v) => {
      const { height, top } = v.ref.current.getBoundingClientRect();
      return top + height - marginTop > 0;
    })[0];
    if (view) {
      if (view.matchToPath === this.props.history.location.pathname) {
        //this.props.history.push(view.matchToPath);
        return;
      }
    }
    this.setState({
      showArrowTop: this.showArrowTop(),
    });
  };

  updateWindowSize = (width) => {
    const { appReducer, setWindowsSizeType } = this.props;
    const windowsSizeType =
      width > 0 && width <= 480
        ? EXTRA_SMALL
        : width > 481 && width <= 768
        ? SMALL
        : width > 769 && width <= 1224
        ? MEDIUM
        : LARGE;

    if (windowsSizeType !== appReducer.windowsSizeType) {
      return setWindowsSizeType(windowsSizeType);
    }
  };

  convertRemToPixels = (rem) =>
    parseFloat(rem) *
    parseFloat(getComputedStyle(document.documentElement).fontSize);

  scrollTo = (posY) => {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: posY,
      },
      1000,
      "swing"
    );
  };

  showMobileMenu = () => {
    this.setState({ showMobileMenu: true });
  };

  onClickHandlerTab = (pathname) => {
    if (this.scrollInterval) clearInterval(this.scrollInterval);
    this.props.showOrHidePortal(false);
    dispatchEvent(new Event("listen2me"));
    this.setState({ clickedOnTab: true }, () => {
      this.props.history.push(pathname);
      this.scrollInterval = setInterval(() => {
        this.setState({ clickedOnTab: false });
        clearInterval(this.scrollInterval);
        this.props.showOrHidePortal(true);
      }, 3000);
    });
  };

  render() {
    const { showMobileMenu } = this.state;
    const marginTop =
      this.state.headerHeight || CSSVariables.HEADER_LARGE_HEIGHT;

    return (
      <div
        className={trimClassNames(["app", styles.app, getNavigatorClass()])}
        style={{ marginTop }}
      >
        <MainHeader
          ref="mainHeader"
          onShowMenu={this.showMobileMenu}
          scrollToTop={this.scrollTo}
          onChangeHandler={(headerHeight) => {
            this.setState({
              headerHeight,
            });
          }}
          onClickHandler={this.onClickHandlerTab}
        />
        <BurgerNav
          show={showMobileMenu}
          onClickHandler={this.onClickHandlerTab}
        />
        <div ref={this.home}>
          <Home marginTop={marginTop} />
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default withRouter(
  connect(
    (store) => ({
      appReducer: store.appReducer,
    }),
    (dispatch) =>
      bindActionCreators(
        {
          setNameToComponent,
          setWindowsSizeType,
          showOrHidePortal,
        },
        dispatch
      )
  )(AppHome)
);
