/* Auto-generated file created by Dan Tovbein 13/02/2019 at 00:09:07hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../utils/_helpers";
import * as CSSVariables from "../../../sass/_variables";
import styles from "./styles.css";
import Image from "../../__shared/Image";
import ButtonRounded from "../../__shared/Button/ButtonRounded";
import ViewSliderItemWrapperImage from "../../ViewSlider/ViewSliderItem/ViewSliderItemWrapper/ViewSliderItemWrapperImage";
import ViewSliderItemWrapperInfo from "../../ViewSlider/ViewSliderItem/ViewSliderItemWrapper/ViewSliderItemWrapperInfo";
import ViewSliderItemWrapperInfoTitle from "../../ViewSlider/ViewSliderItem/ViewSliderItemWrapper/ViewSliderItemWrapperInfo/ViewSliderItemWrapperInfoTitle";
import ViewSliderItemWrapperInfoDescription from "../../ViewSlider/ViewSliderItem/ViewSliderItemWrapper/ViewSliderItemWrapperInfo/ViewSliderItemWrapperInfoDescription";

const MemberItem = props => {
  const { data, onClickHandler } = props;
  return (
    <div className={trimClassNames(["member-item", styles.memberItem])}>
      <ViewSliderItemWrapperImage>
        <Image src={data.thumbnail} />
      </ViewSliderItemWrapperImage>
      <ViewSliderItemWrapperInfo>
        <div className={styles.contact}>
          <div className={styles.info}>
            <ViewSliderItemWrapperInfoTitle title={data.fullName} />
            <ViewSliderItemWrapperInfoDescription description={data.degree} />
          </div>
          {/*
          <nav className={styles.infoNav}>
            <a
              href={`mailto:${data.email}`}
            >
              <img src={emailIcon} />
            </a>
          </nav>
          */}
        </div>
        <footer className={styles.footer}>
          <ButtonRounded
            legend="VER CV"
            backgroundColor={CSSVariables.ORANGE_COLOR_2}
            onClickHandler={() => onClickHandler(data)}
          />
        </footer>
      </ViewSliderItemWrapperInfo>
    </div>
  );
};

MemberItem.defaultProps = {};

MemberItem.propTypes = {
  data: PropTypes.shape({
    degree: PropTypes.string,
    email: PropTypes.string,
    fullName: PropTypes.string,
    resume: PropTypes.string,
    thumbnail: PropTypes.string
  }),
  onClickHandler: PropTypes.func.isRequired
};

export default MemberItem;

/*

      <div
        className={styles.profilePicture}
      >
        <Image
          src={thumbnail}
        />
      </div>
      <div className={styles.contact}>
        <div className={styles.info}>
          <span className={styles.fullName}>{fullName}</span>
          <span className={styles.degree}>{degree}</span>
        </div>
        <nav className={styles.infoNav}>
          <img src={emailIcon} />
        </nav>
      </div>
      <footer className={styles.footer}>
        <ButtonRounded
          backgroundColor={CSSVariables.ORANGE_COLOR_2}
          legend="VER CV"
          onClickHandler={() => {}}
        />
      </footer>

 */
