/* Auto-generated file created by Dan Tovbein 23/03/2019 at 18:54:51hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../utils/_helpers";
import styles from "./styles.css";

const FooterIconLink = (props) => {
  return (
    <a
      className={styles.link}
      href={props.link}
      target="_blank"
      >
    <div 
      className={trimClassNames(["footer-icon-link",
      styles.footerIconLink])}
    >
      <div
        className={styles.wrapperIcon}
      >
        <img
          className={styles.icon}
          src={props.icon}
        />
      </div>
    </div>
    </a>
  )
};

FooterIconLink.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string,
  link: PropTypes.string
};

export default FooterIconLink;
