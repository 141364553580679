/* Auto-generated file created by dtovbeinJC 02/11/2018 at 20:16:07hs */
import {COURSES} from "../../constants/index";
export const GET_COURSES = "GET_COURSES";

export const setNameToComponent = (componentName) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { coursesReducer } = getState();
    return resolve(dispatch({
      type: COURSES,
      payload: {
        name: componentName
      }
    }));
  })
  .then((payload) => Promise.resolve({...payload}))
  .catch((error) => Promise.reject({...error}));
};
