// Auto-generated file created by Dan Tovbein 23/12/2019 at 10:50:35hs
// READ ONLY FILE, NOT EDITABLE!
export const BACKGROUND_COLOR = "#EEEFF0";
export const ORANGE_COLOR_1 = "#FFBC00";
export const ORANGE_COLOR_2 = "#FF7F00";
export const BLACK_COLOR_1 = "#231F20";
export const BLACK_COLOR_2 = "#373435";
export const HEADER_SMALL_HEIGHT = "5rem";
export const HEADER_LARGE_HEIGHT = "7rem";
export const FONTS_URL = "../src/media/fonts/";
export const GEOGRAPH_REGULAR = "geograph-regular";
export const GEOGRAPH_REGULAR_ITALIC = "geograph-regular-italic";
export const GEOGRAPH_LIGHT = "geograph-light";
export const GEOGRAPH_MEDIUM = "geograph-medium";
export const GEOGRAPH_BOLD = "geograph-bold";
export const GEOGRAPH_BOLD_ITALIC = "geograph-bold-italic";
