/* Auto-generated file created by dtovbeinJC 02/11/2018 at 20:11:55hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../utils/_helpers";
import * as CSSVariables from "../../sass/_variables";
import styles from "./styles.css";
import logo from "../../media/images/logo-punto-seguido.svg";
import logoSmall from "../../media/images/logo-punto-seguido-small.svg";
import burgerIcon from "../../media/images/icons/burger_icon.svg";
import MainHeaderNavButton from "./MainHeaderNavButton";
import { getNavigatorClass } from "../../utils/utils";
import facebook from "../../media/images/icons/facebook_header.svg";
import instagram from "../../media/images/icons/IG_logo.svg";
import ButtonRounded from "../__shared/Button/ButtonRounded";
import NavLink from "react-router-dom/es/NavLink";

class MainHeader extends React.Component {
  static defaultProps = {};

  static propTypes = {
    onChangeHandler: PropTypes.func,
    onShowMenu: PropTypes.func,
    onClickHandler: PropTypes.func,
    scrollToTop: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      __name: "MainHeader",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("scroll", this.onScrollHandler);
  }

  componentWillMount() {
    this.setState(
      {
        isSmall: false,
      },
      () => {}
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isSmall !== this.state.isSmall) {
      this.props.onChangeHandler(
        this.state.isSmall
          ? CSSVariables.HEADER_SMALL_HEIGHT
          : CSSVariables.HEADER_LARGE_HEIGHT
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.onScrollHandler);
  }

  render() {
    const { onShowMenu, onClickHandler } = this.props;
    const { isSmall } = this.state;
    const navigatorClass = getNavigatorClass();
    return (
      <div
        className={trimClassNames([
          "main-header",
          styles.mainHeader,
          isSmall ? styles.isSmall : "",
          navigatorClass,
        ])}
        onClick={() => {}}
      >
        <div className={trimClassNames([styles.wrapperLogo, navigatorClass])}>
          <a href="index.html">
            <img className={styles.logo} src={isSmall ? logoSmall : logo} />
          </a>
        </div>

        <img
          onClick={onShowMenu}
          className={trimClassNames([styles.btnBurger, navigatorClass])}
          src={burgerIcon}
        />

        <div className={trimClassNames([styles.navs, navigatorClass])}>
          <nav className={styles.mainNav}>
            <MainHeaderNavButton
              legend={"INICIO"}
              to={"index.html"}
              onClickHandler={onClickHandler}
            />
            <MainHeaderNavButton
              legend={"QUIÉNES SOMOS"}
              basePath="quienes.html"
              options={[
                {
                  legend: "Acerca de nosotros",
                  to: "nosotros.html",
                },
                {
                  legend: "Equipo de dirección",
                  to: "equipo.html",
                },
              ]}
              onClickHandler={onClickHandler}
            />
            <MainHeaderNavButton
              legend={"CURSOS Y ACTIVIDADES"}
              to={"cursos.html"}
              onClickHandler={onClickHandler}
            />
            <MainHeaderNavButton
              legend={"PUBLICACIONES"}
              basePath="publicaciones.html"
              options={[
                { legend: "Artículos", to: "articulos.html" },
                {
                  legend: "Libros y revistas",
                  to: "libros.html",
                },
              ]}
              onClickHandler={onClickHandler}
            />
            <MainHeaderNavButton
              legend={"VIDEOS"}
              to={"videos.html"}
              onClickHandler={onClickHandler}
            />
            <MainHeaderNavButton
              legend={"CONTACTO"}
              to={"contacto.html"}
              onClickHandler={onClickHandler}
            />
          </nav>

          <nav className={styles.socialNav}>
            <ButtonRounded
              legend="Campus Virtual"
              to="http://campuspunto.sysprop.net/"
            />
            <a
              href="https://www.instagram.com/puntoseguido2020/"
              target="_blank"
            >
              <img className={styles.facebookIcon} src={instagram} />
            </a>
            <a
              href="https://www.facebook.com/puntoseguidosaludeducacion/"
              target="_blank"
            >
              <img className={styles.facebookIcon} src={facebook} />
            </a>
          </nav>
        </div>
        <div className={styles.borderBottom} />
      </div>
    );
  }

  onScrollHandler = (evt) => {
    this.checkForScrollYPosition();
  };

  checkForScrollYPosition = () => {
    this.setState(
      {
        isSmall: window.scrollY > 300,
      },
      () => {}
    );
  };
}

export default MainHeader;
