/* Auto-generated file created by Dan Tovbein 16/03/2019 at 19:18:35hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../../../utils/_helpers";
import styles from "./styles.css";

const ViewSliderItemWrapperInfo = (props) => {
  return (
    <div
      className={trimClassNames(["view-slider-item-wrapper-info", styles.viewSliderItemWrapperInfo])}
    >
      { props.children }
    </div>
  )
};

ViewSliderItemWrapperInfo.propTypes = {
  children: PropTypes.node
};

export default ViewSliderItemWrapperInfo;
