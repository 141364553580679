/* Auto-generated file created by Dan Tovbein 13/03/2019 at 21:53:58hs */

import React, {Fragment} from "react";
import PropTypes from "prop-types";
import { trimClassNames } from "../../../utils/_helpers";
import styles from "./styles.css";
import PreloaderImage from "../../../media/images/preloader.gif";

const Preloader = (props) => {
  return (
    <div
      className={trimClassNames(["preloader", styles.preloader])}
    >
      <div
        className={styles.preloaderWrapper}
      >
        <img
          className={styles.preloaderImage}
          src={PreloaderImage}
        />
        {
          props.text && (
            <span
              className={styles.preloaderText}
            >
              { props.text }
            </span>
          )
        }
      </div>
    </div>
  )
};

Preloader.propTypes = {
  text: PropTypes.string
};

export default Preloader;
