/* Auto-generated file created by Dan Tovbein 12/02/2019 at 19:32:54hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import {} from "./helpers";
import { getNavigatorClass } from "../../../utils/utils";
import { trimClassNames } from "../../../utils/_helpers";
import * as CSSVariables from "../../../sass/_variables";
import styles from "./styles.css";
import TitleView from "../../__shared/TitleView";

const ViewContent = (props) => {
  const navigatorClass = getNavigatorClass();
  return (
    <div
      className={trimClassNames([
        "view-content",
        styles.viewContent,
        navigatorClass,
      ])}
      style={{ ...props.customStyles }}
    >
      {props.title && <TitleView legend={props.title} />}
      <div className={styles.wrapper}>{props.children}</div>
    </div>
  );
};

ViewContent.defaultProps = {
  customStyles: {},
};

ViewContent.propTypes = {
  title: PropTypes.string,
  customStyles: PropTypes.object,
};

export default ViewContent;
