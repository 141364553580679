/* Auto-generated file created by Dan Tovbein 17/03/2019 at 12:26:45hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import {} from "./helpers";
import { trimClassNames } from "../../../utils/_helpers";
import styles from "./styles.css";

class HtmlText extends React.Component {

  static propTypes = {
    htmlText: PropTypes.string.isRequired
  };

  static getDerivedStateFromProps(nextProps, nextState) {
    return {
      htmlText: nextProps.htmlText,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      __name: "HtmlText",
      htmlText: ""
    };
    this.plainText = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    this.plainText.current.innerHTML = this.state.htmlText;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.htmlText !== this.state.htmlText) {
      this.plainText.current.innerHTML = this.state.htmlText;
    }
  }

  render() {
    return (
      <div
        className={trimClassNames(["html-text", styles.htmlText])}
      >
        <p
          className={styles.plainText}
          ref={this.plainText}
        />
      </div>
    );
  }
}

export default HtmlText;

