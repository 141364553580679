/* Auto-generated file created by dtovbeinJC 06/11/2018 at 21:36:55hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../utils/_helpers";
import styles from "./styles.css";
import YoutubeVideo from "../../YoutubeVideo";
import ViewSliderItemWrapperInfo from "../../ViewSlider/ViewSliderItem/ViewSliderItemWrapper/ViewSliderItemWrapperInfo";
import ViewSliderItemWrapperInfoDescription from "../../ViewSlider/ViewSliderItem/ViewSliderItemWrapper/ViewSliderItemWrapperInfo/ViewSliderItemWrapperInfoDescription";
import ClampLines from "react-clamp-lines";

const VideoItem = props => {
  const { data } = props;
  return (
    <div className={trimClassNames(["video-item", styles.videoItem])}>
      <div className={styles.wrapperVideo}>
        <YoutubeVideo url={data.url} />
      </div>
      <ViewSliderItemWrapperInfo>
        <ClampLines
          text={data.title}
          id="really-unique-id"
          lines={3}
          ellipsis="..."
          className="custom-class"
          innerElement="p"
          buttons={false}
        />
      </ViewSliderItemWrapperInfo>
    </div>
  );
};

VideoItem.defaultProps = {};

VideoItem.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string
  }).isRequired
};

export default VideoItem;
