/* Auto-generated file created by Dan Tovbein 12/02/2019 at 19:14:20hs */

import * as React from "react";
import { connect } from "react-redux";
import {getNavigatorClass} from "../../utils/utils";
import { trimClassNames } from "../../utils/_helpers";
import {sendEmail} from "../../actions/api/index";
import { bindActionCreators } from "redux";
import * as PropTypes from "prop-types";
import { setNameToComponent } from "./actions";
import * as CSSVariables from "../../sass/_variables.js";
import styles from "./styles.css";
import View from "../../components/View";
import ButtonRounded from "../../components/__shared/Button/ButtonRounded";
import ViewContent from "../../components/View/ViewContent";
import FormItem from "../../components/Form/FormItem";
import Form from "../../components/Form";
import ViewContentColumn from "../../components/View/ViewContent/ViewContentColumn";
import Image from "../../components/__shared/Image";

class Contact extends React.Component {

  static defaultProps = {};

  static propTypes = {
    contactReducer: PropTypes.shape({
      name: PropTypes.string
    }),
    setNameToComponent: PropTypes.func,
    sendEmail: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      __name: "Contact",
      formData: {
        fullname: "",
        email: "",
        location: "",
        message: ""
      },
      showMessage: false,
      formMessage: ""
    };
  }

  onSendForm = () => {
    const {formData: {fullname, email, location, message}} = this.state;
    if(fullname === "" || !this.validateEmail(email) || message === "") {
      this.showMessage("Se deberán completar los campos requeridos");
    } else {
      return this.props.sendEmail(this.state.formData)
        .then(payload => {
          this.showMessage("Se envió correctamente la consulta");
        })
        .catch(error => {
          this.showMessage("No se pudo enviar la consulta");
        })
    }
  }

  validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  showMessage = (message) => {
    this.setState({
      formMessage: message
    }, () => {
      setTimeout(() => {
        this.setState({
          formMessage: ""
        })
      }, 3000);
    });
  }
 
  render() {
    const {formMessage} = this.state;
    return (
      <div className={trimClassNames(["contact", styles.contact, getNavigatorClass()])}>
      <View
        marginTop={this.props.marginTop}
        height="auto"
      >
        <ViewContent
          title="Contacto"
          customStyles={{paddingRight: "0"}}
        >
          <ViewContentColumn>
            <div className={styles.wrapperForm}>
              <p>Para enviarnos un mensaje, completá el siguiente formulario y nos pondremos en contacto con vos lo antes posible. ¡Gracias!</p>
              <Form>
                <FormItem
                  label="Nombre y apellido"
                  type="text"
                  isRequired={true}
                  onChangeHandler={(value) => {this.setState({formData: {...this.state.formData, fullname: value}})}}
                />
                <FormItem
                  label="Email"
                  type="text"
                  isRequired={true}
                  onChangeHandler={(value) => {this.setState({formData: {...this.state.formData, email: value}})}}
                />
                <FormItem
                  label="Lugar"
                  type="text"
                  onChangeHandler={(value) => {this.setState({formData: {...this.state.formData, location: value}})}}
                />
                <FormItem
                  label="Mensaje"
                  type="textarea"
                  isRequired={true}
                  onChangeHandler={(value) => {this.setState({formData: {...this.state.formData, message: value}})}}
                />
                
                <footer className={styles.footer}>
                  {
                    (formMessage !== "") && <span className={styles.errorMessage}>{formMessage}</span>
                  }
                  <div className={styles.footerWrapper}>
                    <span className={styles.footerTitle}>
                      <span className={styles.asteric}>*</span>
                      Campos requeridos
                    </span>
                    <ButtonRounded 
                      legend="ENVIAR"
                      backgroundColor={CSSVariables.ORANGE_COLOR_2}
                      onClickHandler={this.onSendForm}
                    />
                  </div>
                </footer>
              </Form>
            </div>
          </ViewContentColumn>

          <ViewContentColumn>
            <div className={styles.wrapperPicture}>
              <Image
                fromPath={true}
                src="views/contact/contacto.jpg"
              />
            </div>
          </ViewContentColumn>

        </ViewContent>
      </View>
      </div>
    );
  }
}

export default connect(
  store => ({
    contactReducer: store.contactReducer
  }),
  dispatch => bindActionCreators({
    setNameToComponent,
    sendEmail
  }, dispatch),
  null,
  { withRef: true })(Contact);
