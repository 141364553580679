/* Auto-generated file created by dtovbeinJC 02/11/2018 at 20:14:12hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../utils/_helpers";
import { isMobile, getWindowFormatSize } from "../../utils/utils.js";
import styles from "./styles.css";
import View from "../View";
import bgHome from "../../media/images/views/home/P_1.jpg";
//import AnimatedArrow from "../__shared/AnimatedArrow";
import DegradedLine from "../__shared/DegradedLine";
import { getNavigatorClass } from "../../utils/utils";
import MainFooter from "../MainFooter";

class Home extends React.Component {
  static defaultProps = {};

  static propTypes = {
    marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  constructor(props) {
    super(props);

    this.state = {
      __name: "Home",
    };
  }

  render() {
    const { marginTop } = this.props;
    const navigatorClass = getNavigatorClass();
    const windowFormatSizeClass = getWindowFormatSize();
    return (
      <View
        marginTop={marginTop}
        height={
          isMobile() ||
          windowFormatSizeClass === "small" ||
          windowFormatSizeClass === "medium"
            ? "100%"
            : null
        }
      >
        <div
          className={trimClassNames(["home", styles.home, navigatorClass])}
          style={{ backgroundImage: `url(${bgHome})` }}
        >
          <div className={trimClassNames([styles.wrapper, navigatorClass])}>
            <h1 className={styles.title}>
              Intersecciones entre Salud y Educación
            </h1>
            <DegradedLine />
            <p>
              <span className={styles.highlighted}>Punto Seguido</span>, el
              nombre que busca expresar la continuidad, el movimiento y el
              dinamismo que plantea el trabajo de formación e intercambio. Una
              tarea permanente que se despliega en el marco de la fluidez de la
              vida social y subjetiva en la actualidad. Fluidez, que no se opone
              a convicciones y compromisos y nos convoca a seguir. Enuncia la
              necesidad de detenernos, de puntuar, de hacer un punto que abra a
              la reflexión, al pensamiento, a la búsqueda incesante de los
              cambios que ayuden a transformar y mejorar los procesos del campo
              de la salud y de la educación.
            </p>
          </div>
          {
            // <AnimatedArrow/>
          }
        </div>
      </View>
    );
  }
}

export default Home;
