/* Auto-generated file created by dtovbeinJC 05/11/2018 at 23:26:09hs */

import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as PropTypes from "prop-types";
import { setNameToComponent } from "./actions";
import { showOrHidePortal } from "../App/actions";
import { getAll } from "../../actions/api";
import View from "../../components/View";
import ViewSlider from "../../components/ViewSlider";
import ArticleItem from "../../components/Articles/ArticleItem";
import ArticleDetails from "../../components/Articles/ArticleDetails";
import ViewContent from "../../components/View/ViewContent";
import backgroundImage from "../../media/images/views/backgrounds/NEW-FONDOS_5.svg";
import { withRouter } from "react-router-dom";

class Articles extends React.Component {
  static defaultProps = {};

  static propTypes = {
    articlesReducer: PropTypes.shape({
      articles: PropTypes.object,
      layout: PropTypes.shape({
        columns: PropTypes.number,
        rows: PropTypes.number,
      }),
      name: PropTypes.string,
    }),
    getAll: PropTypes.func,
    setNameToComponent: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      __name: "Articles",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { setNameToComponent, getAll } = this.props;
    const { __name } = this.state;

    const promises = [setNameToComponent(__name), getAll("article")];

    let ps;
    let idd;
    let sec;
    Promise.all(promises)
      .then((payload) => {
        if (document.URL.split("?").length > 1) {
          ps = document.URL.split("?").length;
          idd = document.URL.split("?")[ps - 1];
          sec = document.URL.split("/")[document.URL.split("/").length - 1];
          if (sec.split("?")[0] === "articulo")
            document.getElementById(`hckBtnArt${idd}`).click();
        }
        //document.getElementById("hckBtnCurso32").click();
      })
      .catch((error) => console.log(error));
  }

  landSubSection() {
    if (this.props.location.search.length === 0) {
      //console.log("nothimg here");
      return;
    } else {
      console.log(this.props.location.search);
      //this.showOrHidePortal();
    }
  }

  showOrHidePortal() {
    this.props.showOrHidePortal(true);
  }

  render() {
    const {
      articlesReducer: {
        articles,
        layout: { columns, rows },
      },
      id,
      marginTop,
    } = this.props;

    //console.log("entra aca 1");
    if (document.URL.split("?").length > 1) {
      //console.log("entra aca 2");
      if (
        document.URL.split("?")[0].split("#/")[1] === "articulo" ||
        document.URL.split("?")[1].split("#/")[1] === "articulo"
      ) {
        //console.log("entra aca 3");
        //localStorage.clear();
        //localStorage.setItem("articles", JSON.stringify(articles));
      }
    }
    //console.log( JSON.parse( localStorage.getItem("articles") ) );
    return (
      <View id={id} marginTop={marginTop} backgroundImage={backgroundImage}>
        <ViewContent>
          <ViewSlider
            title={"Artículos"}
            data={articles}
            itemComponent={ArticleItem}
            detailsComponent={ArticleDetails}
            columns={columns}
            rows={rows}
            marginTop={marginTop}
            showOrHidePortal={() => this.showOrHidePortal()}
          />
        </ViewContent>
      </View>
    );
  }
}

export default withRouter(
  connect(
    (store) => ({
      articlesReducer: store.articlesReducer,
    }),
    (dispatch) =>
      bindActionCreators(
        {
          setNameToComponent,
          getAll,
          showOrHidePortal,
        },
        dispatch
      ),
    null,
    { withRef: true }
  )(Articles)
);
