/* Auto-generated file created by dtovbeinJC 06/11/2018 at 21:36:43hs */
import { VIDEOS } from "../../constants/index";

export const GET_VIDEOS = "GET_VIDEOS";

export const setNameToComponent = (componentName) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { videosReducer } = getState();
    return resolve(dispatch({
      type: VIDEOS,
      payload: {
        name: componentName
      }
    }));
  })
  .then((payload) => Promise.resolve({...payload}))
  .catch((error) => Promise.reject({...error}));
};
