/* Auto-generated file created by Dan Tovbein 16/03/2019 at 19:19:19hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../../../../utils/_helpers";
import styles from "./styles.css";

const ViewSliderItemWrapperInfoDescription = (props) => {
  return (
    <p
      className={trimClassNames(["view-slider-item-wrapper-info-description", styles.viewSliderItemWrapperInfoDescription])}
    >
      { props.description }
    </p>
  )
};

ViewSliderItemWrapperInfoDescription.propTypes = {
  description: PropTypes.string
};

export default ViewSliderItemWrapperInfoDescription;
