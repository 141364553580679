/* Auto-generated file created by Dan Tovbein 12/02/2019 at 21:46:58hs */

import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as PropTypes from "prop-types";
import { setNameToComponent } from "./actions";
import { getAll } from "../../actions/api";
import { showOrHidePortal } from "../App/actions";
import ViewContent from "../../components/View/ViewContent";
import ViewSlider from "../../components/ViewSlider";
import MemberDetails from "../../components/Members/MemberDetails";
import View from "../../components/View";
import MemberItem from "../../components/Members/MemberItem";
import backgroundImage from "../../media/images/views/backgrounds/NEW-FONDOS_8.svg";

class Members extends React.Component {

  static defaultProps = {};

  static propTypes = {
    membersReducer: PropTypes.shape({
      memmbers: PropTypes.object,
      layout: PropTypes.shape({
        columns: PropTypes.number,
        rows: PropTypes.number
      }),
      name: PropTypes.string
    }),
    getAll: PropTypes.func,
    setNameToComponent: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      __name: "Members"
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { setNameToComponent, getAll} = this.props;
    const { __name } = this.state;

    const promises = [
      setNameToComponent(__name),
      getAll('member'),
    ];

    Promise.all(promises)
      .then((payload) => {})
      .catch((error) => console.log(error));
  }

  showOrHidePortal() {
    this.props.showOrHidePortal(true);
  }

  render() {
    const {membersReducer: {members, layout: {columns, rows}}, marginTop} = this.props;
    return (
      <View
        marginTop={marginTop}
        backgroundImage={backgroundImage}
      >
        <ViewContent>
          <ViewSlider
            data={members}
            detailsComponent={MemberDetails}
            itemComponent={MemberItem}
            columns={columns}
            rows={rows}
            title={"Equipo de dirección"}
            marginTop={marginTop}
            showOrHidePortal={() => this.showOrHidePortal()}
          />
        </ViewContent>
      </View>
    );
  }
}

export default connect(
  store => ({
    membersReducer: store.membersReducer
  }),
  dispatch => bindActionCreators({
    setNameToComponent,
    getAll,
    showOrHidePortal,
  }, dispatch),
  null,
  { withRef: true })(Members);
