/* Auto-generated file created by Dan Tovbein 19/12/2018 at 17:22:39hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import {trimClassNames} from "../../../../utils/_helpers";
import {getNavigatorClass} from "../../../../utils/utils";
import styles from "./styles.css";
import ViewSliderItemDetailsHeaderTabs from "./ViewSliderItemDetailsHeaderTabs";

const ViewSliderItemDetailsHeader = (props) => {
  return (
    <header
      className={trimClassNames(["view-slider-item-details-header", styles.viewSliderItemDetailsHeader, getNavigatorClass()])}
    >
      <div className={styles.wrapperTitle}>
        <h1 className={styles.title}>{props.title}</h1>
        { 
          (props.subtitle) && (
            <span className={styles.subtitle}>{props.subtitle}</span>
          )
        }
      </div>
      <div className={styles.miscellany} />
      {
        (props.tabs && props.tabs.data !== null) && (
          <div className={styles.wrapperTabs}>
            <ViewSliderItemDetailsHeaderTabs
              data={props.tabs.data}
              onClickHandlerTab={props.tabs.onClickHandler}
            />
          </div>
        )
      }
    </header>
  )
};

ViewSliderItemDetailsHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  tabs: PropTypes.shape({
    data: PropTypes.object,
    onClickHandler: PropTypes.func
  }),
  onCloseHandler: PropTypes.func
};

export default ViewSliderItemDetailsHeader;
