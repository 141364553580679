/* Auto-generated file created by dtovbeinJC 04/11/2018 at 20:27:10hs */

import * as React from "react";
import { trimClassNames } from "../../utils/_helpers";
import styles from "./styles.css";
import bgFooter from "../../media/images/views/footer/bg_footer.jpg";
import logo from "../../media/images/logo-punto-seguido-inverted.svg";
import facebook from "../../media/images/icons/facebook.svg";
import instagram from "../../media/images/icons/instagram.svg";
import campusVirtual from "../../media/images/buttons/btn-campus-virtual.svg";
import WrapperLinks from "./WrapperLinks";
import WrapperLink from "./WrapperLinks/WrapperLink";
import IconLink from "./FooterIconLink";
import { getNavigatorClass } from "../../utils/utils";
import NavLink from "react-router-dom/es/NavLink";
import DegradedLine from "../__shared/DegradedLine";

class MainFooter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      __name: "MainFooter",
      links: [
        {
          title: "INICIO",
          links: [
            {
              title: "Quiénes somos",
              link: "quienes.html",
            },
            {
              title: "Equipo de dirección",
              link: "equipo.html",
            },
            { title: "Cursos y actividades", link: "cursos.html" },
            {
              title: "Publicaciones",
              link: "publicaciones.html",
            },
            { title: "Videos", link: "videos.html" },
          ],
        },
      ],
    };
  }

  render() {
    const navigatorClass = getNavigatorClass();
    return (
      <div
        className={trimClassNames([
          "main-footer",
          styles.mainFooter,
          navigatorClass,
        ])}
        style={{ backgroundImage: `url(${bgFooter})` }}
      >
        <div className={styles.wrapperLogo}>
          <img className={styles.logo} src={logo} />
        </div>

        <div className={styles.links}>
          {this.state.links.map((l, a) => (
            <WrapperLinks key={`wrapper-links-${a}`} title={l.title}>
              {l.links.map((i, b) => (
                <WrapperLink
                  key={`wrapper-link-${b}`}
                  link={i.link}
                  title={i.title}
                />
              ))}
            </WrapperLinks>
          ))}
          <WrapperLinks title="CONTACTO">
            <a className={styles.emailText} href="mailto:info@puntoseguido.com">
              info@puntoseguido.com
            </a>
            <nav className={styles.socialNav}>
              <IconLink
                icon={facebook}
                link="https://facebook.com/PuntoSeguidoSaludEducacion"
              />
              <IconLink
                icon={instagram}
                link="https://instagram.com/puntoseguido2020"
              />
            </nav>
          </WrapperLinks>

          <WrapperLinks>
            <div className={styles.buttonCampus}>
              <a href={"http://campuspunto.sysprop.net/"} target="_blank">
                <img src={campusVirtual} />
              </a>
            </div>
          </WrapperLinks>
        </div>

        <div className={styles.linksShort}>
          <div>
            <div className={styles.shortNav}>
              <DegradedLine />
              {this.state.links[0].links.map((l, a) => (
                <a className={styles.navLink} key={`link-${a}`} href={l.link}>
                  <span>{l.title}</span>
                </a>
              ))}
              <a className={styles.navLink} href={`contacto.html`}>
                <span>Contacto</span>
              </a>
            </div>
            <div className={styles.shortSocialNav}>
              <a
                href="https://facebook.com/PuntoSeguidoSaludEducacion"
                target="_blank"
              >
                <img src={facebook} />
              </a>

              <a href="https://instagram.com/puntoseguido2019" target="_blank">
                <img src={instagram} />
              </a>
            </div>
          </div>
          <div>
            <div className={styles.buttonCampus}>
              <a href={"http://campuspunto.sysprop.net/"} target="_blank">
                <img src={campusVirtual} />
              </a>
            </div>
          </div>
        </div>

        <small className={styles.copyright}>
          <br />© 2020 Punto Seguido. Todos los derechos reservados | Diseño por
          mkdesign
        </small>
      </div>
    );
  }
}

export default MainFooter;
