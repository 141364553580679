/* Auto-generated file created by Dan Tovbein 20/12/2018 at 11:32:25hs */

import * as React from "react";
import {} from "./helpers";
import { trimClassNames } from "../../../utils/_helpers";
import styles from "./styles.css";

const DegradedLine = (props) => {
  return (
    <div className={trimClassNames(["degraded-line",
                    styles.degradedLine])}>
    </div>
  )
};

DegradedLine.propTypes = {};

export default DegradedLine;
