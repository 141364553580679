/* Auto-generated file created by Dan Tovbein 21/12/2018 at 14:51:49hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import {} from "./helpers";
import { trimClassNames } from "../../../../utils/_helpers";
import * as CSSVariables from "../../../../sass/_variables";
import styles from "./styles.css";
import TabSectionTitle from "./TabSectionTitle";
import HtmlText from "../../../__shared/HtmlText";

class TabSection extends React.Component {

  static propTypes = {
    data: PropTypes.shape({
      label: PropTypes.string,
      description: PropTypes.string
    }).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      __name: "TabSection"
    };
  }

  render() {
    return (
      <div
        className={trimClassNames(["tab-section", styles.tabSection])}>
        <TabSectionTitle legend={this.props.data.label}/>
        <HtmlText htmlText={this.props.data.description}/>
      </div>
    );
  }
}

export default TabSection;

