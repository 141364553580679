import {matchPath} from "react-router-dom";
import {EXTRA_SMALL, LARGE, MEDIUM, SMALL} from "../constants/shared";

/**
 *
 * @param pathname
 * @return {*}
 */
export const getParamsFromPath = (pathname) => {
  const basePath = "/";
  const paths = [
    matchPath(pathname, {path: `${basePath}publicaciones` }),
    matchPath(pathname, {path: `${basePath}publicaciones/libros-y-revistas` }),
    matchPath(pathname, {path: `${basePath}publicaciones/libros-y-revistas/:id` }),
    matchPath(pathname, {path: `${basePath}publicaciones/articulos` }),
    matchPath(pathname, {path: `${basePath}publicaciones/articulos/:id` }),
    matchPath(pathname, {path: `${basePath}cursos-y-actividades` }),
    matchPath(pathname, {path: `${basePath}cursos-y-actividades/:id` }),
    matchPath(pathname, {path: `${basePath}articulos` }),
    matchPath(pathname, {path: `${basePath}articulos/:id` }),
    matchPath(pathname, {path: `${basePath}videos` }),
    matchPath(pathname, {path: `${basePath}videos/:id` })
  ];
  const filteredPath = paths.filter(path => (path !== null && path.isExact))[0];
  return (filteredPath !== undefined) ? filteredPath.params : null;
};

export const routes = {
  "course": "/cursos-y-actividades",
  "article": "/ariticulos",
  "editorial": "/libros-y-revistas",
  "videos": "/videos"
};

export const getMonth = (month) => {
  const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septimbre", "Octubre", "Noviembre", "Diciembre"];
  return months[month];
}

/**
 DD de MM
 */
export const parseDate = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.getDate()} de ${getMonth(date.getMonth())}`;
};

/**
 DD de MM, YYYY
*/
export const parseFullDate = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.getDate()} de ${getMonth(date.getMonth())}, ${date.getFullYear()}`;
};

export const ellipsis = (str = "", maxLength = 100) => {
  if(str !== null) {
    return (str.length > maxLength) ? (
      `${str.slice(0, maxLength - 4)} ...`
    ) : (
      str
    );
  }
  return "";
}

export const getWindowFormatSize = () => {
  const EXTRA_SMALL = "extra-small";
  const SMALL = "small";
  const MEDIUM = "medium";
  const LARGE = "large";
  const width = window.innerWidth;
  return (
    (width > 0 && width <= 480)
      ? EXTRA_SMALL
      : (width > 481 && width <= 768)
        ? SMALL
        : (width > 769 && width <= 1224)
          ? MEDIUM
          : LARGE
  );
};

export const isMobile = () => (
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)
);

export const isLandscape = () => {
  return (window.matchMedia("(orientation: portrait)").matches) ? "portrait" : "landscape";
}

export const getNavigatorClass = () => {
  const formatSize = getWindowFormatSize();
  return isMobile() ? `mobile ${formatSize} ${isLandscape()}` : `desktop ${formatSize}`;
};