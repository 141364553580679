/* Auto-generated file created by dtovbeinJC 05/11/2018 at 23:26:42hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../utils/_helpers";
import * as CSSVariables from "../../../sass/_variables";
import styles from "./styles.css";
import ButtonRounded from "../../__shared/Button/ButtonRounded";
import ViewSliderItemWrapperInfoDate from "../../ViewSlider/ViewSliderItem/ViewSliderItemWrapper/ViewSliderItemWrapperInfo/ViewSliderItemWrapperInfoDate";
import { parseFullDate } from "../../../utils/utils";
import ViewSliderItemWrapperInfoTitle from "../../ViewSlider/ViewSliderItem/ViewSliderItemWrapper/ViewSliderItemWrapperInfo/ViewSliderItemWrapperInfoTitle";
import ViewSliderItemWrapperInfo from "../../ViewSlider/ViewSliderItem/ViewSliderItemWrapper/ViewSliderItemWrapperInfo";

const ArticleItem = (props) => {
  const { data, onClickHandler } = props;

  //console.log(window.location.href);

  /*let open = false;

  if (data.id === 56) {
    if (!open) {
      open = false;
      console.log(data.title);
      setTimeout(() => {
        onClickHandler(data);
      }, 1000);
    }
  }*/

  //console.log(localStorage.getItem("articles"));
  //console.log("a ver... " + data.id + " - " + data.title);

  return (
    <div className={trimClassNames(["article-item", styles.articleItem])}>
      <ViewSliderItemWrapperInfo>
        <ViewSliderItemWrapperInfoTitle title={data.title} />
        <span className={styles.author}>{data.author}</span>
        <footer className={styles.footer}>
          <ButtonRounded
            id={`hckBtnArt${data.id}`}
            legend="VER MÁS"
            backgroundColor={CSSVariables.ORANGE_COLOR_2}
            onClickHandler={() => onClickHandler(data)}
          />
        </footer>
      </ViewSliderItemWrapperInfo>
    </div>
  );
};

ArticleItem.defaultProps = {};

ArticleItem.propTypes = {
  data: PropTypes.shape({
    author: PropTypes.string,
    createdAt: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  onClickHandler: PropTypes.func.isRequired,
};

export default ArticleItem;
