/* Auto-generated file created by Dan Tovbein 12/02/2019 at 19:14:20hs */

import { CONTACT } from "../../constants/";

export const initialState = {
  name: ""
}

export default function reducer(state=initialState, action) {
  switch(action.type) {
    case CONTACT: {
      return {
        ...state,
        name: action.payload.name
      };
    }

    case `${CONTACT}_REQUEST`: {
      return {
        ...state
      }
    }

    case `${CONTACT}_SUCCESS`: {
      return {
        ...state
      }
    }

    case `${CONTACT}_FAILED`: {
      return {
        ...state
      }
    }

    default:
      return state;
  }
}
