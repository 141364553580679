/* Auto-generated file created by dtovbeinJC 04/11/2018 at 21:10:34hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { trimClassNames } from "../../utils/_helpers";
import styles from "./styles.css";
import ViewSliderItem from "./ViewSliderItem";
import TitleView from "../__shared/TitleView";
import Portal from "../Portal";
import Preloader from "../__shared/Preloader";
import ViewSliderHeaderNav from "./ViewSliderHeaderNav";

class ViewSlider extends React.Component {
  static propTypes = {
    columns: PropTypes.number,
    data: PropTypes.object,
    rows: PropTypes.number,
    title: PropTypes.string.isRequired,
    marginTop: PropTypes.string.isRequired,
    detailsComponent: PropTypes.func,
    itemComponent: PropTypes.func.isRequired,
    showPortal: PropTypes.bool.isRequired,
  };

  static getDerivedStateFromProps(nextProps, nextState) {
    return {
      columns: nextProps.columns,
      rows: nextProps.rows,
      data: nextProps.data,
    };
  }

  constructor(props) {
    super(props);

    this.debounceTime = 50;

    this.state = {
      __name: "ViewSlider",
      currentPage: 0,
      columns: 1,
      itemData: null,
      showDetails: false,
      rows: 1,
      data: null,
    };
    this.portal = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeHandler);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.data !== null &&
      JSON.stringify(prevState.data) !== JSON.stringify(this.state.data)
    ) {
      this.paginate();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeHandler);
  }

  paginate = () => {
    const { columns, data, rows } = this.state;

    const maxItemsPerPage = columns * rows;
    let pageNumber = 0;

    let pagination = {};
    let page, item;

    Object.keys(data).forEach((key, index) => {
      if (index % maxItemsPerPage === 0) {
        pageNumber++;
        page = {
          [`${pageNumber}`]: {},
        };
        pagination = { ...pagination, ...page };
      }

      item = {
        [`${key}`]: {
          ...data[key],
        },
      };

      pagination[`${pageNumber}`] = {
        ...pagination[`${pageNumber}`],
        ...item,
      };
    });

    this.setState({ pagination });
  };

  onNextHandler = () => {
    this.setState({
      currentPage:
        this.state.currentPage + 1 < Object.keys(this.state.pagination).length
          ? this.state.currentPage + 1
          : 0,
    });
  };

  onPrevHandler = () => {
    this.setState({
      currentPage:
        this.state.currentPage - 1 < 0
          ? Object.keys(this.state.pagination).length - 1
          : this.state.currentPage - 1,
    });
  };

  onClickHandlerItem = (itemData) => {
    this.props.showOrHidePortal(true);
    this.setState({
      showDetails: true,
      itemData,
    });
  };

  onCloseHandlerDetails = () => {
    this.setState({
      showDetails: false,
      itemData: null,
    });
  };

  resizeHandler = debounce(() => {
    //this.setState({currentPage: 0}, this.paginate());
  }, this.debounceTime);

  render() {
    const {
      data,
      detailsComponent,
      itemComponent,
      title,
      marginTop,
      appReducer,
    } = this.props;
    const {
      columns,
      rows,
      pagination,
      currentPage,
      showDetails,
      itemData,
    } = this.state;

    return (
      <div className={trimClassNames(["view-slider", styles.viewSlider])}>
        <header>
          <TitleView legend={title} />
          <ViewSliderHeaderNav
            currentPage={currentPage}
            pagination={pagination}
            onPrevHandler={this.onPrevHandler}
            onNextHandler={this.onNextHandler}
          />
        </header>
        {data === null ? (
          <Preloader text={`Cargando ${title.toLowerCase()}`} />
        ) : (
          <React.Fragment>
            <div className={styles.content}>
              <div
                className={styles.wrapperPages}
                style={{ left: `-${currentPage * 100}%` }}
              >
                {pagination &&
                  Object.keys(pagination).map((page) => (
                    <div key={`page-${page}`} className={styles.page}>
                      {Object.keys(pagination[page]).map((item) => (
                        <ViewSliderItem
                          key={`${item}`}
                          customStyle={{
                            height: `${100 / rows}%`,
                            width: `${100 / columns}%`,
                          }}
                        >
                          {React.createElement(itemComponent, {
                            data: data[item],
                            onClickHandler: (itemData) => {
                              this.onClickHandlerItem(itemData);
                            },
                          })}
                        </ViewSliderItem>
                      ))}
                    </div>
                  ))}
              </div>
            </div>
          </React.Fragment>
        )}
        {showDetails && detailsComponent && appReducer.showPortal ? (
          <Portal
            ref={(node) => (this.portal = node)}
            onRemoveContent={this.onCloseHandlerDetails}
            marginTop={marginTop}
            show={appReducer.showPortal}
          >
            {React.createElement(detailsComponent, {
              data: itemData,
              onCloseHandler: () => {
                this.portal.onCloseHandler();
              },
            })}
          </Portal>
        ) : null}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (store) => ({
      appReducer: store.appReducer,
    }),
    null
  )(ViewSlider)
);
