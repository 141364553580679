/* Auto-generated file created by Dan Tovbein 20/12/2018 at 15:58:27hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import {} from "./helpers";
import { trimClassNames } from "../../../../../utils/_helpers";
import { getNavigatorClass } from "../../../../../utils/utils";
import * as CSSVariables from "../../../../../sass/_variables";
import styles from "./styles.css";
import ViewSliderItemDetailsHeaderTabsTab from "./ViewSliderItemDetailsHeaderTabsTab";

class ViewSliderItemDetailsHeaderTabs extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      label: PropTypes.string
    }).isRequired,
    onClickHandlerTab: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedTab: null
    }
  }

  onClickHandlerTab = (label) => {
    this.setState({
      selectedTab: label
    }, this.props.onClickHandlerTab(label));
  }

  render() {
    const {data, onClickHandlerTab} = this.props;
    return (
      <div
        className={trimClassNames(["view-slider-item-details-header-tabs", styles.viewSliderItemDetailsHeaderTabs, getNavigatorClass()])}
      >
        {
          Object.values(data).map((tab, index) => (
            <div key={tab.label}
                 className={styles.tab}>
              <ViewSliderItemDetailsHeaderTabsTab
                label={tab.label}
                isSelected={(index === 0 && this.state.selectedTab === null) ? true : tab.label === this.state.selectedTab}
                onClickHandler={this.onClickHandlerTab}
              />
              {
                (index < Object.values(data).length - 1) && <span className={styles.divisor}>|</span>
              }
            </div>
          ))
        }
      </div>
    )
  }
};

export default ViewSliderItemDetailsHeaderTabs;
