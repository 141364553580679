/* Auto-generated file created by Dan Tovbein 13/02/2019 at 07:48:55hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import {} from "./helpers";
import { trimClassNames } from "../../../../utils/_helpers";
import * as CSSVariables from "../../../../sass/_variables";
import styles from "./styles.css";
import {getNavigatorClass} from "../../../../utils/utils";

const ViewContentColumn = (props) => {
  const navigatorClass = getNavigatorClass();
  return (
    <aside
      className={trimClassNames(["view-content-column", styles.viewContentColumn, navigatorClass])}
    >
      { props.children }
    </aside>
  )
};

ViewContentColumn.defaultProps = {
};

ViewContentColumn.propTypes = {
};

export default ViewContentColumn;
