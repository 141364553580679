/* Auto-generated file created by Dan Tovbein 15/02/2019 at 18:58:15hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../utils/_helpers";
import styles from "./styles.css";
import DegradedLine from "../../__shared/DegradedLine";

const WrapperLinks = (props) => {
  return (
    <div
      className={trimClassNames(["wrapper-links", styles.wrapperLinks])}>
      {
        (props.title) && (
          <React.Fragment>
            <h1 className={styles.title}>{ props.title }</h1>
            <DegradedLine/>
          </React.Fragment>
        )
      }
      <div
        className={styles.linksList}
      >
      { props.children }
      </div>
    </div>
  )
};

WrapperLinks.propTypes = {
  title: PropTypes.string
};

export default WrapperLinks;
