/* Auto-generated file created by dtovbeinJC 07/11/2018 at 07:43:27hs */

import { APP } from "../../constants/index";

export const SET_WINDOWS_SIZE_TYPE = "SET_WINDOWS_SIZE_TYPE";
export const SHOW_OR_HIDE_PORTAL = "SHOW_OR_HIDE_PORTAL";

export const setNameToComponent = (componentName) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { appReducer } = getState();
    return resolve(dispatch({
      type: APP,
      payload: {
        name: componentName
      }
    }));
  })
  .then((payload) => Promise.resolve({...payload}))
  .catch((error) => Promise.reject({...error}));
};

export const setWindowsSizeType = (windowsSizeType) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { appReducer } = getState();
    return resolve(dispatch({
      type: SET_WINDOWS_SIZE_TYPE,
      payload: {
        type: windowsSizeType
      }
    }));
  })
    .then((payload) => Promise.resolve({...payload}))
    .catch((error) => Promise.reject({...error}));
};

export const showOrHidePortal = (show) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    return resolve(dispatch({
      type: SHOW_OR_HIDE_PORTAL,
      payload: {
        show
      }
    }))
  })
};
