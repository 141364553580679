/* Auto-generated file created by dtovbeinJC 06/11/2018 at 21:39:17hs */

import { EDITORIALS } from "../../constants/";
import {GET_EDITORIALS} from "./actions";
import {EXTRA_SMALL, MEDIUM, SMALL} from "../../constants/shared";
import {SET_WINDOWS_SIZE_TYPE} from "../App/actions";
import {GET_ALL} from "../../actions/api";
import {GET_COURSES} from "../Courses/actions";

export const initialState = {
  name: "",
  editorials: null,
  layout: {
    columns: 1,
    rows: 1
  }
}

export default function reducer(state=initialState, action) {
  switch(action.type) {
    case EDITORIALS: {
      return {
        ...state,
        name: action.payload.name
      };
    }

    case `${GET_ALL}_EDITORIAL_REQUEST`: {
      return {
        ...state
      }
    }

    case `${GET_ALL}_EDITORIAL_SUCCESS`: {
      return {
        ...state,
        editorials: action.payload
      }
    }

    case `${GET_COURSES}_EDITORIAL_FAILED`: {
      return {
        ...state
      }
    }

    case SET_WINDOWS_SIZE_TYPE: {
      const { type } = action.payload;
      return {
        ...state,
        layout: {
          columns: (type === EXTRA_SMALL || type === SMALL) ? 1 : (type === MEDIUM) ? 2 : 3,
          rows: 2
        }
      };
    }

    default:
      return state;
  }
}