/* Auto-generated file created by Dan Tovbein 12/02/2019 at 21:46:58hs */

import { MEMBERS } from "../../constants/";
import {GET_MEMBERS} from "./actions";
import {SET_WINDOWS_SIZE_TYPE} from "../App/actions";
import {EXTRA_SMALL, MEDIUM, SMALL} from "../../constants/shared";
import {GET_ALL} from "../../actions/api";
import {GET_COURSES} from "../Courses/actions";

export const initialState = {
  name: "",
  members: null,
  layout: {
    columns: 1,
    rows: 1
  }
}

export default function reducer(state=initialState, action) {
  switch(action.type) {
    case MEMBERS: {
      return {
        ...state,
        name: action.payload.name
      };
    }

    case `${GET_ALL}_MEMBER_REQUEST`: {
      return {
        ...state
      }
    }

    case `${GET_ALL}_MEMBER_SUCCESS`: {
      return {
        ...state,
        members: action.payload
      }
    }

    case `${GET_COURSES}_MEMBER_FAILED`: {
      return {
        ...state
      }
    }

    case SET_WINDOWS_SIZE_TYPE: {
      const { type } = action.payload;
      return {
        ...state,
        layout: {
          columns: (type === EXTRA_SMALL || type === SMALL) ? 1 : (type === MEDIUM) ? 2 : 3,
          rows: (type === EXTRA_SMALL || type === SMALL) ? 1 : (type === MEDIUM) ? 1 : 1
        }
      };
    }

    default:
      return state;
  }
}
