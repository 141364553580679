/* Auto-generated file created by dtovbeinJC 03/12/2018 at 20:38:26hs */

import * as React from "react";
import * as ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../utils/_helpers";
import { getNavigatorClass } from "../../utils/utils";
import styles from "./styles.css";
import shareIcon from "../../media/images/icons/PS-01.svg";
import shareIconTW from "../../media/images/icons/PS-04.svg";
import shareIconMail from "../../media/images/icons/PS-02.svg";
import shareIconClip from "../../media/images/icons/PS-03.svg";

export const XButton = (props) => (
  <svg
    width="31px"
    height="31px"
    viewBox="-6.291 -4.79 31 31"
    enable-background="new -6.291 -4.79 31 31"
  >
    <g>
      <circle opacity="0.85" fill="#FFFFFF" cx="9.209" cy="10.71" r="15.5" />
      <g>
        <line
          fill="none"
          stroke="#F17E20"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          x1="0.499"
          y1="2"
          x2="17.919"
          y2="19.42"
        />
        <line
          fill="none"
          stroke="#F17E20"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          x1="17.919"
          y1="2"
          x2="0.499"
          y2="19.42"
        />
      </g>
    </g>
  </svg>
);
let txt;
let myLoc;

const tryToFill = () => {
  setTimeout(() => {
    if (JSON.parse(localStorage.getItem("editorials")) !== null) {
      txt.innerHTML = JSON.parse(localStorage.getItem("editorials"));

      let json = JSON.parse(localStorage.getItem("editorials"));
      let str = "";

      Object.keys(json).forEach(function(key) {
        if (String(json[key].id) === myLoc) {
          str += "<h1>" + json[key].title + "</h1> <br>";

          str +=
            "Docentes: <span class='highlight'>" +
            json[key].author +
            "</span><br>";

          str +=
            "<span class='highlight'>Editorial: </span> " +
            json[key].company +
            "<br>";

          str += json[key].description + " <br>";
          str +=
            "<br><img width='90%' height='auto' src=" +
            json[key].picture +
            "></img><br><br>";

          txt.innerHTML = str;
        }
      });
    } else tryToFill();

    //
  }, 1000);
};

class Portal3 extends React.Component {
  static propTypes = {
    onRemoveContent: PropTypes.func.isRequired,
    marginTop: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      __name: "Portal",
      rerender: false,
    };

    //console.log(props.location.search);
  }

  componentDidMount() {
    this._isMounted = true;
    this.createPortal();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    if (this.portal) this.onCloseHandler();
  }

  componentWillUnmount() {
    this._isMounted = false;
    try {
      this.wrapper.style = `height: 0; opacity: 0;`;
      const timerToClose = setInterval(() => {
        this.props.onRemoveContent();
        clearInterval(timerToClose);
        this.removeFromBody();
      }, 200);
    } catch (error) {}
  }

  removeFromBody() {
    if (this.portal) {
      document.body.removeChild(this.portal);
      ReactDOM.unmountComponentAtNode(this.portal);
    }
  }

  onCloseHandler = () => {
    this.wrapper.style = `height: 0; opacity: 0;`;
    const timerToClose = setInterval(() => {
      //this.props.onRemoveContent();
      try {
        if (this.portal) document.body.removeChild(this.portal);
      } catch (e) {}
      clearInterval(timerToClose);
    }, 200);
  };

  createPortal() {
    if (this.props.location.search.length === 0) {
      return;
    }
    if (this.props.location.pathname !== "/book") return;
    myLoc = this.props.location.search.replace("?", "");

    this.portal = document.createElement("div");
    this.portal.style = `margin-top: ${
      this.props.marginTop
    }; height: calc(100vh - ${this.props.marginTop})`;
    this.portal.className = trimClassNames([
      "portal",
      styles.portal,
      getNavigatorClass(),
    ]);

    this.wrapper = document.createElement("div");
    this.wrapper.className = styles.wrapper;

    let xCloseWrapper = document.createElement("div");
    xCloseWrapper.className = styles.btnClose;
    const xmlns = "http://www.w3.org/2000/svg";
    let xClose = document.createElementNS(xmlns, "svg");
    xClose.onclick = this.onCloseHandler;
    xClose.class = styles.btnClose;
    xClose.setAttributeNS(null, "width", "31px");
    xClose.setAttributeNS(null, "height", "31px");
    xClose.setAttributeNS(null, "viewBox", "-6.291 -4.79 31 31");
    xClose.setAttributeNS(null, "enableBackground", "new -6.291 -4.79 31 31");

    let shareImg = document.createElement("img");
    shareImg.src = shareIcon;
    shareImg.style.height = "31px";

    let shareTweet = document.createElement("img");
    shareTweet.src = shareIconTW;
    shareTweet.style.height = "31px";

    let shareMail = document.createElement("img");
    shareMail.src = shareIconMail;
    shareMail.style.height = "31px";

    let shareCopy = document.createElement("img");
    shareCopy.src = shareIconClip;
    shareCopy.style.height = "31px";

    let shareLink = document.createElement("a");
    shareLink.href = String(
      `http://facebook.com/sharer/sharer.php?u=${document.URL}`
    ).replace("#", "%23");
    shareLink.target = "_blank";
    shareLink.appendChild(shareImg);

    let shareLinkTW = document.createElement("a");
    shareLinkTW.href = String(
      `https://twitter.com/intent/tweet?text=Punto Seguido &url=${document.URL}`
    ).replace("#", "%23");
    shareLinkTW.target = "_blank";
    shareLinkTW.appendChild(shareTweet);

    let shareLinkMail = document.createElement("a");
    shareLinkMail.href = String(
      `mailto:?subject=Punto Seguido&amp;body=Entrá en este link ${
        document.URL
      }`
    );
    shareLinkMail.target = "_blank";
    shareLinkMail.appendChild(shareMail);

    let shareLinkCopy = document.createElement("a");
    let tmpTxt = document.createElement("input");
    tmpTxt.style.display = "none";
    tmpTxt.readOnly = true;
    tmpTxt.value = document.URL;
    shareLinkCopy.onclick = () => {
      tmpTxt.style.display = "inline";
      tmpTxt.select();
      document.execCommand("copy");
      tmpTxt.style.display = "none";
      shareLinkCopy.style.opacity = "0.2";
    };

    shareLinkCopy.appendChild(shareCopy);
    shareLinkCopy.appendChild(tmpTxt);

    txt = document.createElement("div");
    txt.className = "course-details";
    txt.innerHTML = "<p>CARGANDO...</p>";
    tryToFill();

    let g1 = document.createElementNS(xmlns, "g");
    let circle = document.createElementNS(xmlns, "circle");
    circle.setAttributeNS(null, "opacity", "1");
    circle.setAttributeNS(null, "fill", "#F17E20");
    circle.setAttributeNS(null, "cx", "9.209");
    circle.setAttributeNS(null, "cy", "10.71");
    circle.setAttributeNS(null, "r", "15.5");

    let g2 = document.createElementNS(xmlns, "g");
    let line1 = document.createElementNS(xmlns, "line");
    line1.setAttributeNS(null, "fill", "none");
    line1.setAttributeNS(null, "stroke", "#FFF");
    line1.setAttributeNS(null, "strokeWidth", "3");
    line1.setAttributeNS(null, "strokeLinecap", "round");
    line1.setAttributeNS(null, "strokeLinejoin", "round");
    line1.setAttributeNS(null, "strokeMiterlimit", "10");
    line1.setAttributeNS(null, "x1", "0.499");
    line1.setAttributeNS(null, "y1", "2");
    line1.setAttributeNS(null, "x2", "17.919");
    line1.setAttributeNS(null, "y2", "19.42");
    let line2 = document.createElementNS(xmlns, "line");
    line2.setAttributeNS(null, "fill", "none");
    line2.setAttributeNS(null, "stroke", "#FFF");
    line2.setAttributeNS(null, "strokeWidth", "3");
    line2.setAttributeNS(null, "strokeLinecap", "round");
    line2.setAttributeNS(null, "strokeLinejoin", "round");
    line2.setAttributeNS(null, "strokeMiterlimit", "10");
    line2.setAttributeNS(null, "x1", "17.919");
    line2.setAttributeNS(null, "y1", "2");
    line2.setAttributeNS(null, "x2", "0.499");
    line2.setAttributeNS(null, "y2", "19.42");
    g2.appendChild(line1);
    g2.appendChild(line2);
    xClose.appendChild(g1);
    g1.appendChild(circle);
    g1.appendChild(g2);
    xCloseWrapper.appendChild(xClose);

    this.wrapper.appendChild(shareLink);
    this.wrapper.appendChild(shareLinkTW);
    this.wrapper.appendChild(shareLinkMail);
    this.wrapper.appendChild(shareLinkCopy);

    this.wrapper.appendChild(xCloseWrapper);
    this.wrapper.appendChild(txt);
    this.portal.appendChild(this.wrapper);

    document.body.appendChild(this.portal);

    const timerToAnimate = setInterval(() => {
      this.wrapper.style = `height: 100%; opacity: 1;`;
      clearInterval(timerToAnimate);
    }, 100);
  }

  render() {
    const { children } = this.props;
    return <>{this.wrapper && ReactDOM.createPortal(children, this.wrapper)}</>;
  }
}

export default withRouter(Portal3);
