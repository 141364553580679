/* Auto-generated file created by dtovbeinJC 02/11/2018 at 20:16:07hs */

import { COURSES } from "../../constants/";
import { GET_COURSES } from "./actions";
import {GET_ALL} from "../../actions/api";
import { SET_WINDOWS_SIZE_TYPE } from "../App/actions";
import {EXTRA_SMALL, LARGE, MEDIUM, SMALL } from "../../constants/shared";

export const initialState = {
  name: "",
  courses: null,
  layout: {
    columns: 1,
    rows: 1
  }
};

export default function reducer(state=initialState, action) {
  switch(action.type) {
    case COURSES: {
      return {
        ...state,
        name: action.payload.name
      };
    }

    case `${GET_ALL}_COURSE_REQUEST`: {
      return {
        ...state
      }
    }

    case `${GET_ALL}_COURSE_SUCCESS`: {
      return {
        ...state,
        courses: action.payload
      }
    }

    case `${GET_COURSES}_COURSE_FAILED`: {
      return {
        ...state
      }
    }

    case SET_WINDOWS_SIZE_TYPE: {
      const { type } = action.payload;
      return {
        ...state,
        layout: {
          columns: (type === EXTRA_SMALL || type === SMALL) ? 1 : (type === MEDIUM) ? 2 : 3,
          rows: 1
        }
      };
    }


    default:
      return state;
  }
}