/* Auto-generated file created by Dan Tovbein 17/03/2019 at 19:29:33hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../utils/_helpers";
import { getNavigatorClass } from "../../../utils/utils";
import styles from "./styles.css";
import ViewSliderItemDetails from "../../ViewSlider/ViewSliderItemDetails";
import ViewSliderItemDetailsHeader from "../../../components/ViewSlider/ViewSliderItemDetails/ViewSliderItemDetailsHeader";
import ViewSliderItemDetailsWrapper from "../../ViewSlider/ViewSliderItemDetails/ViewSliderItemDetailsWrapper";
import ViewSliderItemDetailsWrapperSide from "../../ViewSlider/ViewSliderItemDetails/ViewSliderItemDetailsWrapperSide";
import emailIcon from "../../../media/images/icons/email-white.svg";
import Image from "../../__shared/Image";
import HtmlText from "../../__shared/HtmlText";

class MemberDetails extends React.Component {
  static defaultProps = {};

  static propTypes = {
    data: PropTypes.shape({
      description: PropTypes.string,
      email: PropTypes.string,
      fullName: PropTypes.string,
      id: PropTypes.number,
      isActive: PropTypes.bool,
      thumb: PropTypes.string,
      resume: PropTypes.string,
    }).isRequired,
    onCloseHandler: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      __name: "MemberDetails",
    };
  }

  getFileExtension = (file = null) => {
    let extension = "";
    if (file) {
      let type = file.slice(0, file.indexOf(";"));
      type = type.slice(type.indexOf("/") + 1, type.length);
      switch (type) {
        case "msword":
          extension = "doc";
          break;
        case "vnd.ms-excel":
          extension = "xls";
          break;
        case "vnd.ms-powerpoint":
          extension = "ppt";
          break;
        case "plain":
          extension = "txt";
          break;
        case "markdown":
          extension = "md";
          break;
        default:
          extension = type;
      }
    }
    return extension;
  };

  renderWrapperImage = () => {
    const { data } = this.props;
    return (
      <React.Fragment>
        <div className={styles.wrapperImage}>
          <Image src={data.thumb} />
        </div>
        <div className={styles.extraInfo}>
          <img className={styles.emailIcon} src={emailIcon} />
          <span className={styles.span}>{data.email}</span>
        </div>
        <nav className={styles.extraNav}>
          <a
            className={styles.fileLink}
            href={data.resume}
            download={`cv_${data.fullName
              .toLowerCase()
              .replace(/ /g, "_")}.${this.getFileExtension(data.resume)}`}
          >
            DESCARGAR CV COMPLETO
          </a>
        </nav>
      </React.Fragment>
    );
  };

  render() {
    const { data, onCloseHandler } = this.props;
    return (
      <ViewSliderItemDetails>
        <div
          className={trimClassNames([
            "member-details",
            styles.memberDetails,
            getNavigatorClass(),
          ])}
        >
          <div className={styles.wrapperImageMobile}>
            {this.renderWrapperImage()}
          </div>
          <ViewSliderItemDetailsHeader
            title={data.fullName}
            subtitle="Psicólogo Magister"
            onCloseHandler={onCloseHandler}
          />
          <ViewSliderItemDetailsWrapper>
            <ViewSliderItemDetailsWrapperSide>
              <div style={styles.headerContent}>
                <HtmlText htmlText={data.description} />
              </div>
            </ViewSliderItemDetailsWrapperSide>
            <ViewSliderItemDetailsWrapperSide>
              {this.renderWrapperImage()}
            </ViewSliderItemDetailsWrapperSide>
          </ViewSliderItemDetailsWrapper>
        </div>
      </ViewSliderItemDetails>
    );
  }
}

export default MemberDetails;
