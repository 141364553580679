/* Auto-generated file created by Dan Tovbein 12/02/2019 at 21:46:58hs */
import {MEMBERS} from "../../constants/index";
export const GET_MEMBERS = "GET_MEMBERS";

export const setNameToComponent = (componentName) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { membersReducer } = getState();
    return resolve(dispatch({
      type: MEMBERS,
      payload: {
        name: componentName
      }
    }));
  })
  .then((payload) => Promise.resolve({...payload}))
  .catch((error) => Promise.reject({...error}));
};