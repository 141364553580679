/* Auto-generated file created by Dan Tovbein 02/01/2019 at 14:35:31hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../utils/_helpers";
import styles from "./styles.css";
import playIcon from "../../media/images/icons/arrow_right.svg";

const YoutubeVideo = (props) => {
  //console.log(props.url.split("?")[1].replace("v=", ""));
  const parseYoutubeUrl = (url) => {
    let parsedUrl = "";
    if (url) {
      parsedUrl = url.substring(url.indexOf("v=") + 2);
      parsedUrl =
        parsedUrl.indexOf("&") !== -1
          ? parsedUrl.slice(0, parsedUrl.indexOf("&"))
          : parsedUrl;
    }
    return parsedUrl;
  };

  return (
    <div className={trimClassNames(["youtube-video", styles.youtubeVideo])}>
      <div className={styles.wrapper}>
        {props.url && (
          <a
            href={`https://www.youtube.com/watch?v=${parseYoutubeUrl(
              props.url
            )}`}
            target="_blank"
          >
            <div className={styles.wrapper2}>
              <img
                height="auto"
                width="100%"
                src={playIcon}
                //props.url.split("?")[1].replace("v=", "")
                //src="https://img.youtube.com/vi/xrogrEsP5fg/0.jpg"
                src={`https://img.youtube.com/vi/${parseYoutubeUrl(
                  props.url
                )}/0.jpg`}
              />
            </div>
          </a>

          /*<iframe
            frameBorder="0"
            src={`https://www.youtube.com/embed/${parseYoutubeUrl(props.url)}`}
          />*/
        )}
      </div>
    </div>
  );
};

YoutubeVideo.propTypes = {
  url: PropTypes.string,
};

export default YoutubeVideo;
