// Auto-generated file created by Dan Tovbein 23/12/2019 at 10:29:25hs
export const APP_VERSION = "0.1.1";
export const SERVER_ENVIRONMENT_MODE = "staging";
export const ENVIRONMENTS = {
  production: { key: "production", url: "https://puntoseguido.com/api/v1/" },
  staging: {
    key: "staging",
    url: "https://puntoseguido.com/api/v1/"
    //url: "http://localhost:8080/web-punto-seguido-api/api/v1/"
  }
};
