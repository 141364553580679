/* Auto-generated file created by dtovbeinJC 06/11/2018 at 21:39:17hs */

import { EDITORIALS } from "../../constants/index";

export const GET_EDITORIALS = "GET_EDITORIALS";

export const setNameToComponent = (componentName) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { editorialsReducer } = getState();
    return resolve(dispatch({
      type: EDITORIALS,
      payload: {
        name: componentName
      }
    }));
  })
  .then((payload) => Promise.resolve({...payload}))
  .catch((error) => Promise.reject({...error}));
};