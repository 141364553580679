/* Auto-generated file created by Dan Tovbein 20/12/2018 at 23:03:55hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../../../utils/_helpers";
import styles from "./styles.css";

const TabSectionTitle = (props) => {
  return (
    <h1 className={trimClassNames(["tab-section-title",
      styles.tabSectionTitle])}>
      { props.legend }
    </h1>
  )
};

TabSectionTitle.propTypes = {
  legend: PropTypes.string.isRequired
};

export default TabSectionTitle;
