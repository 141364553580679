/* Auto-generated file created by dtovbeinJC 06/11/2018 at 21:37:44hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../utils/_helpers";
import { getNavigatorClass } from "../../../utils/utils";
import styles from "./styles.css";
import ViewSliderItemDetails from "../../ViewSlider/ViewSliderItemDetails";
import ViewSliderItemDetailsHeader from "../../../components/ViewSlider/ViewSliderItemDetails/ViewSliderItemDetailsHeader";
import ViewSliderItemDetailsWrapper from "../../ViewSlider/ViewSliderItemDetails/ViewSliderItemDetailsWrapper";
import ViewSliderItemDetailsWrapperSide from "../../ViewSlider/ViewSliderItemDetails/ViewSliderItemDetailsWrapperSide";
import Image from "../../__shared/Image";
import HtmlText from "../../__shared/HtmlText";
import fbIcon from "../../../media/images/icons/PS-01.svg";
import twIcon from "../../../media/images/icons/PS-04.svg";
import mlIcon from "../../../media/images/icons/PS-02.svg";
import cpIcon from "../../../media/images/icons/PS-03.svg";

class EditorialDetails extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      author: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.number,
      isActive: PropTypes.bool,
      picture: PropTypes.string,
      title: PropTypes.string,
    }).isRequired,
    onCloseHandler: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      __name: "EditorialDetails",
    };
  }

  render() {
    const { data, onCloseHandler } = this.props;
    let thisUrl = `https://puntoseguido.com/publicaciones.html#/book?${
      data.id
    }`;
    let fbLink = String(
      `http://facebook.com/sharer/sharer.php?u=${thisUrl}`
    ).replace("#", "%23");

    let twLink = String(
      `https://twitter.com/intent/tweet?text=Punto Seguido Publicaciones &url=${thisUrl}`
    ).replace("#", "%23");

    let mlLink = String(
      `mailto:?subject=Punto Seguido&amp;body=Entrá en este link ${thisUrl}`
    );

    let dummy = document.createElement("div");
    let tmpTxt = document.createElement("input");
    tmpTxt.style.display = "none";
    tmpTxt.readOnly = true;
    tmpTxt.value = thisUrl;
    const copy2ClipBoard = (e) => {
      tmpTxt.style.display = "inline";
      tmpTxt.select();
      document.execCommand("copy");
      tmpTxt.style.display = "none";
      console.log(e.target);
      e.target.style.opacity = "0.3";
    };

    dummy.appendChild(tmpTxt);
    document.body.appendChild(dummy);
    return (
      <ViewSliderItemDetails>
        <div
          className={trimClassNames([
            "editorial-details",
            styles.editorialDetails,
            getNavigatorClass(),
          ])}
        >
          <div>
            <a target="_blank" href={fbLink}>
              <img width="31" src={fbIcon} />
            </a>
            <a target="_blank" href={twLink}>
              <img width="31" src={twIcon} />
            </a>
            <a target="_blank" href={mlLink}>
              <img width="31" src={mlIcon} />
            </a>
            <a onClick={copy2ClipBoard}>
              <img width="31" src={cpIcon} />
            </a>
          </div>
          <div className={styles.wrapperImageMobile}>
            <Image src={data.picture} />
            <div style={styles.headerContent}>
              <p>
                <span className={styles.topic}>{data.topic}</span>
                <span>
                  Docentes:{" "}
                  <span className={styles.authors}>{data.author}</span>
                </span>
                <span className={styles.company}>
                  Editorial: {data.company}
                </span>
              </p>
            </div>
          </div>
          <ViewSliderItemDetailsHeader
            title={data.title}
            onCloseHandler={onCloseHandler}
          />
          <ViewSliderItemDetailsWrapper>
            <ViewSliderItemDetailsWrapperSide>
              <div style={styles.headerContent}>
                <p>
                  <span className={styles.topic}>{data.topic}</span>
                  <span>
                    Docentes:{" "}
                    <span className={styles.authors}>{data.author}</span>
                  </span>
                  <span className={styles.company}>
                    Editorial: {data.company}
                  </span>
                </p>
                <HtmlText htmlText={data.description} />
              </div>
            </ViewSliderItemDetailsWrapperSide>
            <ViewSliderItemDetailsWrapperSide>
              <div className={styles.wrapperImage}>
                <Image src={data.picture} />
              </div>
            </ViewSliderItemDetailsWrapperSide>
          </ViewSliderItemDetailsWrapper>
        </div>
      </ViewSliderItemDetails>
    );
  }
}

export default EditorialDetails;
