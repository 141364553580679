import React, { Fragment } from "react";
import ReactDOM, { render } from "react-dom";
import { Provider } from "react-redux";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import Store from "./store";
import "./index.css";
import App from "./containers/App";
import AppHome from "./containers/AppHome";
import AppQuienes from "./containers/AppQuienes";
import AppEquipo from "./containers/AppEquipo";
import AppCursos from "./containers/AppCursos";
import WhoWeAre from "./components/WhoWeAre";
import Editorials from "./containers/Editorials";
import Courses from "./containers/Courses";
import Articles from "./containers/Articles";
import * as serviceWorker from "./serviceWorker";
import AppContacto from "./containers/AppContacto";
import AppVideos from "./containers/AppVideos";
import AppPublicaciones from "./containers/AppPublicaciones";
import AppBooks from "./containers/AppBooks";
import AppCursosDetail from "./containers/AppCursosDetail";

render(
  <Provider store={Store}>
    <Router>
      <Fragment>
        <Switch>
          <Route path={`/`} component={AppHome} />
        </Switch>
      </Fragment>
    </Router>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
