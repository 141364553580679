/* Auto-generated file created by dtovbeinJC 02/11/2018 at 20:15:57hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../utils/_helpers";
import {
  isMobile,
  getWindowFormatSize,
  getNavigatorClass
} from "../../utils/utils.js";
import styles from "./styles.css";
import View from "../View";
import ViewContent from "../View/ViewContent";
import ViewContentColumn from "../View/ViewContent/ViewContentColumn";
import Image from "../__shared/Image";
import backgroundImage from "../../media/images/views/backgrounds/NEW-FONDOS_5.svg";

class WhoWeAre extends React.Component {
  static propTypes = {
    marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };

  constructor(props) {
    super(props);

    this.state = {
      __name: "WhoWeAre"
    };
  }

  render() {
    const { marginTop } = this.props;
    const windowFormatSizeClass = getWindowFormatSize();
    return (
      <View
        marginTop={marginTop}
        height={"100%"}
        backgroundImage={backgroundImage}
      >
        <ViewContent title="Quiénes somos" customStyles={{ paddingRight: "0" }}>
          <ViewContentColumn>
            <div className={styles.wrapperInfo}>
              <p>
                <span className="highlight">Punto Seguido</span> es un espacio
                de intercambio y formación en el campo de las intersecciones
                entre salud y educación.
              </p>
              <p>
                Un ámbito en permanente construcción que reúne y convoca a
                educadores, psicólogos, pedagogos, psicopedagogos, trabajadores
                sociales, psicoanalistas y otros profesionales y trabajadores
                interesados en este territorio.
              </p>
              <p>
                Las preocupaciones que orientan el análisis y las intervenciones
                de Punto Seguido están relacionadas con las problemáticas
                subjetivas, sociales, institucionales y comunitarias; con las
                actuales configuraciones de las infancias y las adolescencias y
                de los demás actores sociales en los escenarios educativos,
                asistenciales. A partir de 2004 fundamos un espacio virtual con
                el propósito de ampliar y enriquecer la trama institucional. En
                estos años hemos implementado una vasta propuesta de formación a
                través de Internet. Se han dictado cursos sobre la vida
                cotidiana y los conflictos en las instituciones educativas,
                sobre las problemáticas subjetivas y los aprendizajes, sobre los
                procesos de orientación y elección vocacional, acerca de la
                mediación como alternativa para el abordaje de determinados
                problemas de la convivencia escolar y a la temática de los
                cuentos para niños, desde la perspectiva del psicoanálisis.
              </p>
              <p>
                Junto al Centro de Estudios Multidisciplinarios (cem) hemos
                llevado a cabo otras propuestas de formación académica en torno
                a las problemáticas de las infancias y adolescencias en los
                actuales contextos sociales, a los contenidos relacionados con
                la diversidad y la concepción de una pedagogía intercultural, al
                museo como espacio público de comunicación y aprendizaje.
              </p>
            </div>
          </ViewContentColumn>
          <ViewContentColumn>
            <div
              className={trimClassNames([
                styles.wrapperPicture,
                getNavigatorClass()
              ])}
            >
              <Image fromPath={true} src="views/who-we-are/P-05.jpg" />
            </div>
          </ViewContentColumn>
        </ViewContent>
      </View>
    );
  }
}

export default WhoWeAre;
