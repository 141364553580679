/* Auto-generated file created by Dan Tovbein 16/03/2019 at 12:18:45hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../../utils/_helpers";
import styles from "./styles.css";

const ViewSliderItemWrapper = (props) => {
  return (
    <div
      className={trimClassNames(["view-slider-item-wrapper", styles.viewSliderItemWrapper])}
    >
      { props.children }
    </div>
  )
};

ViewSliderItemWrapper.propTypes = {
  children: PropTypes.node
};

export default ViewSliderItemWrapper;
