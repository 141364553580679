/* Auto-generated file created by dtovbeinJC 02/11/2018 at 20:16:07hs */

import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as PropTypes from "prop-types";
import {} from "./helpers";
import { setNameToComponent } from "./actions";
import { showOrHidePortal } from "../App/actions";
import { getAll } from "../../actions/api";
import View from "../../components/View";
import ViewSlider from "../../components/ViewSlider";
import CourseItem from "../../components/Courses/CourseItem";
import CourseDetails from "../../components/Courses/CourseDetails";
import ViewContent from "../../components/View/ViewContent";
import backgroundImage from "../../media/images/views/backgrounds/NEW-FONDOS_3.svg";

class Courses extends React.Component {
  static propTypes = {
    coursesReducer: PropTypes.shape({
      courses: PropTypes.object,
      layout: PropTypes.shape({
        columns: PropTypes.number,
        rows: PropTypes.number,
      }),
      name: PropTypes.string,
    }),
    getAll: PropTypes.func,
    setNameToComponent: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      __name: "Courses",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { setNameToComponent, getAll } = this.props;
    const { __name } = this.state;

    const promises = [setNameToComponent(__name), getAll("course")];
    let ps;
    let idd;
    Promise.all(promises)
      .then((payload) => {
        if (document.URL.split("?").length > 1) {
          ps = document.URL.split("?").length;
          idd = document.URL.split("?")[ps - 1];
          document.getElementById(`hckBtnCurso${idd}`).click();
        }
        //document.getElementById("hckBtnCurso32").click();
      })
      .catch((error) => console.log(error));
  }

  showOrHidePortal() {
    this.props.showOrHidePortal(true);
  }

  render() {
    const {
      coursesReducer: {
        courses,
        layout: { columns, rows },
      },
      marginTop,
    } = this.props;
    if (document.URL.split("?").length > 1) {
      if (
        document.URL.split("?")[0].split("#/")[1] === "curso" ||
        document.URL.split("?")[1].split("#/")[1] === "curso"
      ) {
        //localStorage.clear();
        //localStorage.setItem("courses", JSON.stringify(courses));
      }
    }

    return (
      <View marginTop={marginTop} backgroundImage={backgroundImage}>
        <ViewContent>
          <ViewSlider
            data={courses}
            detailsComponent={CourseDetails}
            itemComponent={CourseItem}
            columns={columns}
            rows={rows}
            title={"Cursos"}
            marginTop={marginTop}
            showOrHidePortal={() => this.showOrHidePortal()}
          />
        </ViewContent>
      </View>
    );
  }
}

export default connect(
  (store) => ({
    coursesReducer: store.coursesReducer,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setNameToComponent,
        getAll,
        showOrHidePortal,
      },
      dispatch
    ),
  null,
  { withRef: true }
)(Courses);
