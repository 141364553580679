/* Auto-generated file created by Dan Tovbein 21/12/2018 at 12:15:53hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../../utils/_helpers";
import * as CSSVariables from "../../../../sass/_variables";
import styles from "./styles.css";
import Button from "../index";

const ButtonRounded = (props) => {
  return (
    <Button onClickHandler={props.onClickHandler} to={props.to}>
      <div
        id={props.id}
        className={trimClassNames(["button-rounded", styles.buttonRounded])}
        style={{
          backgroundColor: `${
            props.backgroundColor
              ? props.backgroundColor
              : CSSVariables.BLACK_COLOR_1
          }`,
        }}
      >
        {props.legend}
      </div>
    </Button>
  );
};

ButtonRounded.propTypes = {
  backgroundColor: PropTypes.string,
  legend: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func,
  to: PropTypes.string,
};

export default ButtonRounded;
