/* Auto-generated file created by Dan Tovbein 16/03/2019 at 19:18:55hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../../../../utils/_helpers";
import styles from "./styles.css";

const ViewSliderItemWrapperInfoDate = (props) => {
  return (
    <span
      className={trimClassNames(["view-slider-item-wrapper-info-date", styles.viewSliderItemWrapperInfoDate])}
    >
      { props.date }
    </span>
  )
};

ViewSliderItemWrapperInfoDate.propTypes = {
  date: PropTypes.string
};

export default ViewSliderItemWrapperInfoDate;
