/* Auto-generated file created by Dan Tovbein 20/12/2018 at 16:04:53hs */

import * as React from "react";
import * as PropTypes from "prop-types";
import { trimClassNames } from "../../../../../../utils/_helpers";
import { getNavigatorClass } from "../../../../../../utils/utils";
import styles from "./styles.css";

const ViewSliderItemDetailsHeaderTabsTab = (props) => {
  return (
    <div 
      className={trimClassNames([
        "view-slider-item-details-header-tabs-tab",
        styles.viewSliderItemDetailsHeaderTabsTab,
        `${props.isSelected ? styles.selected : ""}`
      ])}
        onClick={(evt) => {
          evt.preventDefault()
          props.onClickHandler(props.label)
        }}
         role={"button"}
    >
      {props.label}
    </div>
  )
};

ViewSliderItemDetailsHeaderTabsTab.propTypes = {
  label: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  isSelected: PropTypes.bool
};

export default ViewSliderItemDetailsHeaderTabsTab;
